#product {
	// hero section
	&-hero-single {
		position: relative;
		@include heroBG('product-single');
	}
	&-hero-team {
		position: relative;
		@include heroBG('product-team');
	}
	&-hero-corporate {
		position: relative;
		@include heroBG('product-corporate');
	}
	&-hero-accountant {
		position: relative;
		@include heroBG('product-accountant');
	}
	// product single page
	&-single-benefit {
		@include benefitBG('single', (true, true, false, true));
	}
	// product team page
	&-team-benefit {
		@include benefitBG('team', (false, true, true, true));
	}

	// product corporate page
	&-corporate-benefit {
		@include benefitBG('corporate', (false, true, false, true, false));
		&-3{
			.product-image {
				right:-200px;
			}
		}
	}

	// product accountant page
	&-accountant-benefit {
		@include benefitBG('accountant', (true, true, false, true));
	}
	// nav section
	&-nav {
		background: $grey-light-background;
		&-links {
			> li {
				float: left;
				margin-right: 45px;
				line-height: 88px;
				color: $text-grey-color;
				transition: all .15s ease-in-out;
				&:hover {
					color: $text-black-color;
					box-shadow: inset 0 -2px 0 $green;
				}
				> a {
					display: block;
				}
			}
		}
	}

	// overview section
	&-overview {
		padding-top: rhythm(14.5);
		padding-bottom: rhythm(7);
		background-image: linear-gradient(0deg, rgba(251,252,252,0.00) 0%, #F5F7F7 100%);
		&-title-group {
			margin-bottom: rhythm(6.75);
		}
		&-title {
			max-width: 640px;
			margin: 0 auto rhythm(1.5);
		}
		&-intro {
			max-width: 900px;
			margin: auto;
		}

		&-features {
			@include clearfix;
		}
		@at-root {
			.product-overview-feature {
				font-size: 18px;
				line-height: 32px;
				text-align: center;
				margin-bottom: rhythm(8);
				&:before {
					content: "";
					display: block;
					margin: 0 auto rhythm(1.5);
				}
			}
		}
	}

	// how-it-work section
	&-tabs {
		&-wrap {
			background: $grey-dark-background;
		}
		&-controller {
			> li {
				float: left;
				width: percentage(1/3);
				height: 80px;
				color: $text-gloomy-color;
				background: $grey-light-border;
				border-left: 1px solid $grey-dark-background;
				transition: color .15s ease-in-out, background .15s ease-in-out;
				cursor: pointer;
				&:hover {
					color: $text-dark-color;
					background: darken($grey-light-border,1);
				}
				&.active {
					color: $text-dark-color;
					background: $grey-dark-background;
					> a:before {
						color: $white;
						background: $green;
					}
				}
				> a {
					display: block;
					padding: 19px 0 17px 45px;
					font-size: 18px;
					line-height: 44px; // 80px - 18px * 2;
					&:before {
						content: attr(data-step);
						float: left;
						margin-right: 15px;
						width: 44px;
						height: 44px;
						line-height: 47px;
						text-align: center;
						font-size: 22px;
						color: $landing-tab-active-border;
						background: $grey-light-background;
						border-radius: 50%;
						transition: color .15s ease-in-out, background .15s ease-in-out;
					}
				}
			}
		}
		&-content {
			position: relative;
		}
		@at-root {
			.browser {
				box-shadow: 0px 0px 40px 0px rgba(0,0,0,0.1);
			}

			.product-tab {
				&-content {
					padding-top: rhythm(15);
					padding-bottom: rhythm(15);
					min-height: 760px;
					line-height: 28px;
					color: $text-grey-color;
					> #getapp {
						margin-top: rhythm(3);
					}
				}
				&-image {
					position: absolute;
					bottom: 0;
					margin-left: 60px;
				}
				&-title {
					margin-bottom: rhythm(1.25);
				}
				&-intro {
					margin-bottom: rhythm(3.5);
					@extend .title-intro;
				}
				&-list {
					margin-bottom: rhythm(3);
					> li {
						position: relative;
						margin-bottom: rhythm(2);
						padding-left: 40px;
						&:before {
							content: "";
							position: absolute;
							top: 0;
							left: 0;
							@include SVGsprite('Icon--tick');
						}
						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}

	// benefits section
	&-benefits {
		@at-root {
			.product-benefit {
				&-entry {
					position: relative;
					padding-top: rhythm(10);
					padding-bottom: rhythm(17.5);
					background-position: center center;
					background-size: cover;
					background-repeat: no-repeat!important;
					overflow: hidden;
					&:nth-child(2n-1) {
						background-color: $grey-dark-background;
						@include slicer(3) {
							&:after{
								background: transparentize($grey-3, .2)!important;
							}
						}
					}
				}
				&-content {
					&:before {
						content: "";
						display: block;
						margin-bottom: rhythm(3);
					}
					&.left {
						float: left;
					}
					&.right {
						float: right;
					}
					&.center {
						text-align: center;
						margin: 0 auto;
						&.product-benefit-content:before {
							margin-left: auto;
							margin-right: auto;
						}
					}
				}
				&-image {
					position: relative;
					&.left {
						float: left;
						> .product-image {
							position: absolute;
							top: 0;
							right: 45px;
						}
					}
					&.right {
						float: right;
						> .product-image {
							position: absolute;
							top: 0;
							left: 45px;
						}
					}
				}

				&-title {
					margin-bottom: rhythm(1.5);
				}
				&-text {
					font-size: 18px;
					line-height: 32px;
					color: $text-gloomy-color;
					> strong {
						font-weight: normal;
						color: $text-black-color;
					}
				}
			}
			// integration-logos
			.product-integration {
				margin: rhythm(7) (-15px/2) rhythm(2);
				@include clearfix;
				&.grey > &-logo > a {
					background: $grey-dark-background;
				}
				&.white > &-logo > a {
					background: $white;
				}
				&-logo {
					float: left;
					width: percentage(1/3);
					padding: 0 (15px/2);
					margin-bottom: rhythm(2);
					> a {
						display: block;
						height: 88px;
						border-radius: 3px;
						> img {
							display: block;
							height: 100%;
							margin: auto;
							@include dimImage();
						}
					}
				}
			}
		}
	}

	// testimonial
	&-testimonial {
		padding: rhythm(10) 10px rhythm(4);
		background: $grey-light-background;
		> .testimonial {
			max-width: 1000px;
			margin: auto;
		}
		.carousel-inner .item {
			min-height: 200px;
		}
	}
}
