// BASE COLORS

$black: #3C4241;
$white: #FFFFFF;

$grey: #E1E5E5;
$grey-1: #FAFCFC;
$grey-2: #F5F7F7;
$grey-3: #F2F5F4;
$grey-4: #EBF0EF;

$red: #FC694B;
$red-1: #FC6142;

$green: #12C88A;
$green-1: #DFF7EF;
$green-2: #E3FCF4;
$green-3: #F5FCFA;

$text-1: #E1E5E5;
$text-2: #C4CCCB;
$text-3: #ABB3B1;
$text-4: #949998;
$text-5: #7C807F;
$text-6: #636665;
$text-7: #3C4241;
$text-8: #232626;


// TEXT COLORS
$hero-title-color: $black;

$title-color: $black;

$text-black-color: $black;
$text-dark-color: $text-6;
$text-gloomy-color: $text-5;
$text-grey-color: $text-4;
$text-light-color: $text-3;
$text-green-color: $green;

$text-hightlight-color: $black;
$text-hightlight-background: $green-2;

// $text-strong-color: #454B4A;

$link-green-color: $green;


// BACKGROUND COLORS
$white-background: $white;
$grey-light-background: $grey-1;
$grey-dark-background: $grey-2;

$green-background: $green;

$pricing-table-background: linear-gradient(0deg, rgba(251,252,252,0.00) 0%, #F5F7F7 100%);

$footer-background: linear-gradient(-180deg, rgba(223,247,239,0.99) 0%, rgba(255,255,255,0.00) 100%);


// BORDER COLORS
$landing-tab-border: $text-1;
$landing-tab-active-border: $text-2;

$grey-dark-border: $text-2;
$grey-light-border: $grey-4;
$green-border: $green;

$error-background: #FCF5F5;
$error-border: #F7DFDF;

$footer-border: $grey-3;
