#footer {
	position: relative;
	display: block;
	clear: both;
	background: $footer-background;
	overflow: hidden;
	&:after {
		content: "";
		position: absolute;
		top: -5.5%;
		left: -11%;
		z-index: 1;
		@include SVGsprite('Logo--shape');
	}

	// footer top part
	&-top {
		position: relative;
		z-index: 2;
		padding: rhythm(14.75) 0 rhythm(10.25);
		border-bottom: 1px solid $footer-border;
	}
	&-title-group {
		margin-bottom: rhythm(5);
		text-align: center;
		> h2 {
			margin-bottom: rhythm(.5);
		}
		> .large-text {
			line-height: 30px;
			color: $text-dark-color;
		}
	}
	&-trial-form {
		margin: 0 auto rhythm(14);
		padding: 0 10px;
		max-width: 650px;
	}

	// footer bottom part
	&-bottom {
		position: relative;
		z-index: 2;
		padding-top: rhythm(10.5);
		padding-bottom: rhythm(5);
		&-left {
			float: left;
			font-size: 14px;
		}
		&-right {
			float: right;
			margin-top: -5px;
			margin-right: 32px;
		}
	}
	&-logo {
		display: block;
		margin-top: 6px;
		width: 90px;
		height: 90px;
		&:before {
			content: "";
			display: block;
			@include SVGsprite('Logo--icon');
		}
		&:after {
			content: "";
			display: none;
			@include SVGsprite('Logo--icon-nav');
		}

	}
}

// footor top links
.footer-top {
	&-link-title {
		margin-bottom: rhythm(2.5);
		font-weight: normal;
		color: $text-dark-color;
	}
	&-links {
		color: $text-light-color;

		> li {
			line-height: 28px;
			> a{
				transition: color .1s linear;
				display: block;

				&:hover{
					color: $text-gloomy-color;
				}
			}
		}
	}
}

// footer social icons
.footer-social {
	padding-top: rhythm(2.75);
	overflow: hidden;
	&-icon {
		float: left;
		margin-right: 5px;
	}
}
#footer-icon {
	&-twitter {
		@include SVGsprite('Icon--twitter');
		@include dimImage( grayscale(100%) opacity(53%) );
	}
	&-linkedin {
		@include SVGsprite('Icon--linkedin');
		@include dimImage( grayscale(100%) opacity(33%) );
	}
	&-gplus {
		@include SVGsprite('Icon--google-plus');
		@include dimImage( grayscale(100%) opacity(35%) );
	}
}


// footor bottom section
.footer-city-links {
	margin-bottom: 10px;
	color: $text-light-color;
	> a {
		transition: color .1s linear;
		&:hover {
			color: $text-gloomy-color;
		}
	}
}
.footer-term-links {
	color: $text-2;
	> a {
		transition: color .1s linear;
		&:hover {
			color: $text-light-color;
		}
	}
}


@include slicer(2) {
	// footer top styles
	#footer-top {
		padding-top: rhythm(9.5);
		padding-bottom: rhythm(5);
	}
	#footer-title-group {
		margin-bottom: rhythm(4);
		> .primary-title {
			font-size: 45px;
			line-height: 56px;
		}
	}
	#footer-trial-form {
		margin-bottom: rhythm(10.5);
		max-width: 590px;
	}

	// footor bottom styles
	#footer-bottom {
		max-width: 1200px;
		padding-top: rhythm(5.75);
		&-right {
			margin-right: 0
		}
	}
}


@include slicer(3) {
	// footer top
	#footer-top {
		padding-top: rhythm(7.75);
	}
	#footer-title-group {
		margin-bottom: rhythm(3);
		> .primary-title {
			font-size: 28px;
			line-height: 32px;
		}
	}
	#footer-trial-form {
		margin-bottom: rhythm(8.25);
	}
	#footer-top > .container-9 {
		position: relative;
	}
	.footer-grid-2:first-of-type {
		width: 96px;
		padding-left: 20px;
	}
	#footer-logo {
		width: 56px;
		height: 56px;
		&:before {
			display: none;
		}
		&:after {
			display: block;
		}
	}

	// footer bottom
	#footer-bottom-left {
		float: none;
		margin-bottom: rhythm(3.5);
		text-align: center;
	}
	#footer-bottom-right {
		float: none;
		margin: auto;
		width: 328px;
		@include clearfix;
	}
}

@include slicer(4) {
	#footer {
		&-top {
			padding-top: rhythm(7);
			padding-bottom: rhythm(1);
			border-bottom-width: 2px;
		}
		&-title-group {
			padding-left: 20px;
			padding-right: 20px;
			> .primary-title {
				margin-bottom: rhythm(2);
			}
		}
		@at-root {
			.footer-grid-2 {
				margin-bottom: rhythm(3);
				text-align: center;
				&:first-child {
					width: 100%;
				}
			}
			#footer-logo {
				margin: auto;
			}
			.footer-social {
				width: 111px;
				margin: auto;
			}
		}
		&-trial-form {
			padding-left: 20px;
			padding-right: 20px;
		}
		&-bottom {
			padding-top: rhythm(4);
			line-height: 24px;
			&-right {
					width: 100%;
			}
		}
	}
}
