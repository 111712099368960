@function rhythm($times) {
	@return $times * 8px;
}


@mixin clearfix {
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}

@mixin dimImage($option: grayscale(100%) opacity(42%)) {
	filter: $option;
	transition: filter .3s ease-in-out;
	&:hover {
		filter: none;
	}
}


@mixin SVGsprite($svg, $dims: true) {
	@if $dims {
		@extend .svg-#{$svg}-dims;
	}
	@extend .svg-#{$svg};
}


// retian media query
@mixin retina() {
	@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
		@content;
	}
}

@mixin heroBG($img) {
	background-image: url(../images/hero/#{$img}.jpg);
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	overflow: hidden;
	> .container-11 {
		position: relative;
		z-index: 2;
	}
	@include slicer(3) {
		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
			background: transparentize($grey-3, .2);
		}
	}
	@include slicer(4) {
		background-color: $grey-light-border;
		&:before {
			display: none;
		}
	}
	@include retina {
		background-image: url(../images/hero/#{$img}@2x.jpg);
	}
}

@mixin benefitBG($segment, $enable) {
	@for $i from 1 through length($enable) {
		@if nth($enable, $i) {
			&-#{$i} {
				background-image: url(../images/product-#{$segment}/benefit-#{$i}.jpg);
				background-position: center center;
				background-repeat: no-repeat;
				background-size: cover;
				overflow: hidden;
				> .container-9 {
					position: relative;
					z-index: 2;
				}
				@include slicer(3) {
					&:after {
						content: "";
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						z-index: 1;
						background: transparentize($white, .2);
					}
				}
				@include slicer(4) {
					background-image: none;
					&:before {
						display: none;
					}
				}
				@include retina {
					background-image: url(../images/product-#{$segment}/benefit-#{$i}@2x.jpg);
				}
			}
		}
	}
}

