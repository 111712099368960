.tooltip {
	position: absolute;
	z-index: 8888;
	&.bottom {
		> .tooltip-arrow {
			position: absolute;
			top: -8px;
			left: 50%;
			margin-left: -8;
			width: 0;
			height: 0;
			border-bottom: 8px solid $text-3;
			border-left: 8px solid transparent;
			border-right: 8px solid transparent;
		}
	}
}
.tooltip-inner {
	padding: rhythm(2) 20px;
	width: 300px;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	color: $white;
	background: $text-3;
	border-radius: 3px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
