html {
	box-sizing: border-box;
}
*,
*:before,
*:after {
	box-sizing: inherit;
}

body {
	font: 16px/1 'Atlas Grotesk Web', sans-serif;
	margin: 0;
	color: $text-black-color;
	background: $white;
	overflow-x: hidden;
}

a {
	color: inherit;
	text-decoration: none;
	&:active,
	&:hover {
		outline: none;
	}
}

p {
	font-size: 16px;
	line-height: 28px;
	margin-top: 0;
}

ul, ol {
	margin: 0;
	padding: 0;
}

ul {
	list-style: none;
}

img {
	border: none;
}

button {
	display: block;
	margin: 0;
	padding: 0;
	background: transparent;
	border: none;
	outline: none;
	cursor: pointer;
	&:active,
	&:hover {
		outline: none;
	}
}

input, button, textarea {
	appearance: none;
	font-family: inherit;
	font-size: inherit;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

// bootstrap animation
.fade:not(.customers-tab) {
	opacity: 0;
	transition: opacity .15s linear;
	&.in {
		opacity: 1;
	}
}

// bootstrap animation
.modal-backdrop.fade:not(.customers-tab) {
	opacity: 0;
	transition: opacity .15s linear;
	&.in {
		opacity: 0.5;
	}
}
