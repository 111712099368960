#contact {
	&-main {
		padding-top: rhythm(15);
		padding-bottom: rhythm(15);
		background-image: linear-gradient(0deg, rgba(251,252,252,0.00) 0%, #F5F7F7 100%);
		&:after {
			content: "";
			position: absolute;
			top: 5.5%;
			left: -11%;
			z-index: 1;
			@include SVGsprite('Logo--shape');
		}
		> .container-11 {
			position: relative;
			z-index: 2;
		}
		p > a {
			@extend %transform-links;
		}
	}
	&-title-group {
		position: relative;
		z-index: 2;
		margin-bottom: rhythm(14.5);
	}
	&-title {
		margin-bottom: rhythm(2.25);
	}
	&-intro {
		@extend .title-dark-intro;
	}
	&-message-title {
		margin-bottom: rhythm(1.5);
	}
	&-message-intro {
		margin-bottom: rhythm(3.75);
		font-size: 18px;
		line-height: 32px;
		color: $text-gloomy-color;
	}

	&-supports {
		padding-top: rhythm(2.5);
		@at-root {
			.contact-support-item {
				position: relative;
				padding-left: 135px;
				padding-bottom: rhythm(2);
				margin-top: rhythm(5);
				color: $text-grey-color;
				border-bottom: 1px solid $landing-tab-border;
				&:last-of-type {
					margin-bottom: 0;
					border-bottom: none;
				}
				&:before {
					content: "";
					position: absolute;
					top: -8px;
					left: 0;
				}
				> h3 {
					font-weight: 400;
					margin-bottom: rhythm(1.75);
				}
				> .btn-grey {
					display: block;
					margin-top: rhythm(3);
					width: 195px;
					height: 48px;
					line-height: 48px;
					cursor: pointer;
				}
			}
		}
	}

	&-offices {
		padding-top: rhythm(15);
		padding-bottom: rhythm(10);
		background: $grey-dark-background url(../images/contact/office-bg.png) center center no-repeat;
		background-size: cover!important;
		@include retina {
			background: $grey-dark-background url(../images/contact/office-bg@2x.png) center center no-repeat;
		}
		&-title {
			margin-bottom: rhythm(9);
		}
	}
	@at-root {
		// office card
		.contact-office {
			&-card {
				position: relative;
				margin-bottom: rhythm(5);
				background: $white;
				overflow: hidden;
			}
			&-photo {
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				width: 225px;
				border-radius: 3px 0 0 3px;
				background-color: $grey-light-background;
				background-position: center center;
				background-size: cover;
				overflow: hidden;
			}
			&-content {
				padding: 20px 20px 20px 270px;
			}
			&-title {
				font-weight: normal;
				&:after {
					content: "";
					display: block;
					margin-top: 5px;
					margin-bottom: rhythm(1.5);
					width: 20px;
					height: 2px;
					background: $green;
				}
			}
			&-address {
				display: block;
				margin-bottom: rhythm(2);
				font-style: normal;
				line-height: 28px;
				color: $text-grey-color;
			}
			&-btn {
				display: block;
				padding: 10px 5px;
				max-width: 188px;
				line-height: 28px;
				text-align: center;
				color: $text-dark-color;
			}
		}
	}
}
