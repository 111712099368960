/*
				This font software is the property of Commercial Type.

				You may not modify the font software, use it on another website, or install it on a computer.

				License information is available at http://commercialtype.com/eula
				For more information please visit Commercial Type at http://commercialtype.com or email us at info[at]commercialtype.com

				Copyright (C) 2013 Schwartzco Inc.
				License: 1512-EOKOFO
*/


@font-face {
	font-family: 'Atlas Grotesk Web';
	src: url('AtlasGrotesk-Medium-Web.eot');
	src: url('AtlasGrotesk-Medium-Web.eot?#iefix') format('embedded-opentype'),
	url('AtlasGrotesk-Medium-Web.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
}

// .AtlasGrotesk-Medium-Web {
// 	font-family: 'Atlas Grotesk Web';
// 	font-weight: 500;
// 	font-style: normal;
// 	font-stretch: normal;
// }


@font-face {
	font-family: 'Atlas Grotesk Web';
	src: url('AtlasGrotesk-Regular-Web.eot');
	src: url('AtlasGrotesk-Regular-Web.eot?#iefix') format('embedded-opentype'),
	url('AtlasGrotesk-Regular-Web.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
}

// .AtlasGrotesk-Regular-Web {
// 	font-family: 'Atlas Grotesk Web';
// 	font-weight: 400;
// 	font-style: normal;
// 	font-stretch: normal;
// }
