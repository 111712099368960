// breakpoint 1670px
@include slicer(1) {
	// landing page
	#landing {
		&-hero{
			padding-bottom: rhythm(12.5);
		}
		// steps section
		&-steps-content {
			padding-left: 90px;
			&:before, &:after {
				left: -1px;
			}
		}
		&-title {
			font-size: 58px;
			line-height: 72px;
		}

		// benefits section
		&-benefits-title-group {
			margin-bottom: rhythm(8.5);
		}

		@at-root {
			.landing-testimonial {
				padding-left: 30px;
				padding-right: 30px;
			}
		}
	}
	// end landing page

	#trust-logo-pernod {
		top: -18px;
	}

	//product page
	.product-benefit-entry > .container-9 {
		> .product-image {
			height: auto;
			max-width: 30%;
			width: 100%;
			&.left {
				float: left;
				margin-left: 20px !important;
			}
			&.right {
				float: right;
				margin-left: 20px !important;
			}
		}
	}
	#price-plan {
		padding-top: rhythm(12.5);
	}
	#about-hero, #contact-main, #integrations-content {
		padding: rhythm(12.5) 0;
	}
}

// breakpoint 1400px
@include slicer(2) {
	// landing page
	#landing {
		// hero section
		&-hero {
			padding-bottom: rhythm(12.5);
			background-position: center center;
		}
		&-hero-logo {
			margin-bottom: rhythm(10);
		}
		&-hero-title {
			margin-bottom: rhythm(1.5);
			// max-width: 340px;
			font-size: 45px;
			line-height: 56px;
		}
		&-hero-intro {
			margin-bottom: rhythm(2.75);
			font-size: 18px;
			line-height: 32px;
		}

		// steps section
		&-steps-sup {
			margin-bottom: rhythm(3);
		}
		&-steps-title {
			margin-bottom: rhythm(.5);
		}
		&-steps-intro {
			max-width: 580px;
		}
		&-steps-images {
			display: none;
		}
		&-steps-content {
			padding: rhythm(11.75) 20px rhythm(15) 90px;
			&:before, &:after {
				left: 45px;
			}
		}
		@at-root {
			.landing-step {
				&:before {
					left: -67px;
				}
				&:after {
					content: "";
					position: absolute;
					top: 0;
					width: 600px;
					height: 500px;
				}
				&:nth-child(1):after {
					top: -50px;
					left: 120%;
					background: url('../images/landing/iPhone.png') left top no-repeat;
				}
				&:nth-child(2):after {
					left: 128%;
					background: url('../images/landing/iMac.png') left top no-repeat;
				}
				@include retina {
					.landing-step {
						&:nth-child(1):after {
							background: url('../images/landing/iPhone@2x.png') left top no-repeat;
						}
						&:nth-child(2):after {
							background: url('../images/landing/iMac@2x.png') left top no-repeat;
						}
					}
				}
			}
		}

		// features section
		&-features {
			padding: rhythm(10) 0 rhythm(10);
		}
		&-features-title {
			margin-bottom: rhythm(-5);
		}
		@at-root {
			.landing-features-item {
				padding-top: rhythm(10);
			}
		}

		// benefits section
		&-benefits {
			padding-top: rhythm(10);
			padding-bottom: rhythm(10);
		}
		&-benefits-title-group {
			margin-bottom: rhythm(5.6);
		}
		&-benefits-intro {
			max-width: 570px;
			margin: auto;
		}
		&-tabs-controller {
			max-width: 910px;
		}
	}
	// end landing page

	// product page
	.product-hero {
		padding: rhythm(10) 0;
		&-title {
			margin-bottom: rhythm(2);
			font-size: 38px;
			line-height: 48px;
		}
		&-intro {
			margin-bottom: rhythm(2);
			font-size: 18px;
			line-height: 32px;
		}
		&-btn {
			height: 56px;
			padding: 0 32px;
			line-height: 56px;
		}
		&-list {
			> li {
				margin-bottom: rhythm(1);
				font-size: 18px;
				line-height: 28px;
				margin-left: 0;
				&:before {
					top: 0px;
				}
			}
		}
	}
	#product-corporate-benefit-3 .product-image {
		margin: 0 0 64px 110px;
	}
	// end product page


	// pricing page
	#price {
		&-plan{
			padding: rhythm(10) 0;
			&-title-group {
				margin-bottom: rhythm(5);
			}
		}
	}
	.table-column-width {
		width: 220px;
	}

	// about page
	#about-hero {
		padding: rhythm(10) 0;
	}
	.member {
		width: 32%;
	}
	#about-photos {
		width: 390px * 2;
	}
	.about-photo-grid:last-child {
		width: 100%;
		> .about-photo-big, > .about-photo-small {
			float: left;
			width: percentage(1/3);
		}
	}

	// quote page
	#quote-trust {
		.trust-company {
			width: 50% !important;
		}
	}

	// Integrations
	#integrations-content {
		padding: rhythm(10) 0;
	}

	// Contact
	#contact-main {
		padding: rhythm(10) 0;
	}
}


@include slicer(-3) {
	#price-table-header-affix {
		&.affix-tap {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
		}
		&.affix {
			position: fixed;
			top: 85px;
			left: 0;
			right: 0;
			z-index: 100;
		}
		&.affix-bottom {
			position: relative;
			z-index: 100;
		}
	}
}

// breakpoint 1000px
@include slicer(3) {
	// landing page
	#landing {
		// hide elements
		&-hero-logo, &-hero-dropdowns {
			display: none;
		}
		// hero section
		&-hero {
			padding-top: rhythm(22);
			padding-bottom: rhythm(11);
			background-position: center;
		}
		&-hero-content {
			max-width: 590px;
			text-align: center;
		}
		&-hero-title {
			margin-bottom: rhythm(1);
			max-width: 100%;
			font-size: 38px;
			line-height: 48px;
		}
		// steps section
		&-steps {
			padding-top: rhythm(7);
		}
		&-steps-sup {
			margin-bottom: 20px;
		}
		&-steps-content {
			max-width: 590px;
			margin: auto;
			padding: rhythm(7) 10px rhythm(8);
			&:before, &:after {
				content: "";
				display: none;
			}
		}
		@at-root {
			.landing-step {
				margin-bottom: rhythm(5);
				&:before {
					width: 35px;
					height: 35px;
					font-size: 18px;
					line-height: 35px;
					top: 7px;
					left: -60px;
				}
				&:after {
					display: none;
				}
				> h3 {
					font-size: 21px;
				}
			}
		}
		// features seciton
		&-features {
			padding: rhythm(7) 0;
		}
		&-features-title {
			margin-bottom: rhythm(0);
		}
		@at-root {
			.landing-features-item {
				padding-top: rhythm(4);
			}
		}
		&-integrations {
			max-width: 590px;
		}
		// benefits section
		&-benefits {
			padding-top: rhythm(7);
			padding-bottom: rhythm(1);
		}
		&-benefits-title {
			//margin-bottom: rhythm(.5);
		}
		&-tabs-controller {
			margin-bottom: rhythm(6);
		}
		&-tabs-content {
			max-width: 590px;
			margin: auto;
		}
	}
	@at-root {
		.landing-tab-left {
			margin-bottom: rhythm(7);
			text-align: center;
		}
		.landing-tab-benefits {
			margin-bottom: rhythm(4);
		}
		.landing-tab-benefit {
			padding-left: 0;
			margin-bottom: rhythm(4);
			text-align: center;
			&:before {
				position: static;
				display: block;
				margin: 0 auto rhythm(2);
			}
		}
		.landing-tab-more {
			margin: 0 auto;
		}
	}
	// end landing page

	// product page
	.product-hero {
		padding: rhythm(10) 0;
		&-title {
			margin-bottom: rhythm(2);
			font-size: 38px;
			line-height: 48px;
		}
		&-intro {
			margin-bottom: rhythm(2);
			font-size: 18px;
			line-height: 32px;
		}
		&-btn {
			height: 56px;
			padding: 0 32px;
			line-height: 56px;
		}
	}
	#product-tabs-controller > li > a {
		padding-left: 20px
	}
	.product-tab-content {
		min-height: auto;
		padding-top: rhythm(8);
	}
	.product-tab-image {
		display: none;
	}
	.product-benefit-entry {
		padding-bottom: rhythm(0);
		text-align: center;
	}
	.product-benefit-content {
		padding-bottom: rhythm(8);
	}
	.product-benefit-content:before {
		margin-left: auto;
		margin-right: auto;
	}
	.product-image, .addon-video {
		position: static !important;
		display: block;
		max-width: 100%;
		margin: auto;
	}

	// end product page

	// pricing page
	#price-plan-cards {
		background: transparent;
		border: none;
		margin-bottom: 0;
	}
	#price-plan-save20{
		margin-bottom: rhythm(4);
	}
	.price-card {
		float: none;
		width: 100%;
		margin-bottom: rhythm(10);
		background: $white;
		border: 1px solid $grey-dark-border;
		border-radius: 3px;
		&.recommend {
			position: static;
			width: 100%;
			> .price-card-details, > .price-card-features > .price-card-features-item {
				padding-left: 30px;
				padding-right: 30px;
			}
		}
	}
	.price-features-item--right {
		padding-left: 160px;
		&:before {
			left: 25px;
		}
	}
	#price-table-close {
		display: none;
	}

	// customers pgae
	.customers-hero-testimonial {
		padding-top: rhythm(10);
	}
	.customers-hero-content {
		font-size: 22px;
		line-height: 32px;
	}
	.case-filter {
		padding-left: 0 !important;
	}


	// about page
	.member {
		width: 48%;
	}
	#about-photos {
		max-width: 390px;
		width: 100%;
	}
	.about-photo-grid:last-child {
		> .about-photo-big, > .about-photo-small {
			float: none;
			width: 100%;
		}
	}

	// hide background shape
	#price-plan {
		&:after {
			display: none;
		}
	}


	// addon-details page
	#addon-hero-right {
		padding-top: 0;
	}

	#addon-connecting > .container-9 {
		margin-bottom: rhythm(10);
	}
	.addon-connecting-left > h3 {
		margin-top: 0;
	}

	.addon-testimonial {
		&-avatar {
			float: none;
			display: block;
			margin: 0 auto rhythm(2);
		}
		&-content {
			float: none;
			width: 100%;
			text-align: center;
			> p {
				font-size: 18px;
				line-height: 28px;
			}
		}
	}

	// webinar
	.webinar {
		float: none;
		max-width: 450px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		&:first-child {
			border-right: 1px solid $grey-light-border;
			border-radius: 3px;
			margin-bottom: rhythm(5);
		}
		&:last-child {
			border-radius: 3px;
		}
	}

	// Quote page
	#quote {
		&-main {
			.container-title {
				text-align: center;
			}
		}
		&-trust {
			padding-top: rhythm(15);
			.title-intro {
				text-align: center;
			}
		}
	}
}

// breakpoist 680px
@include slicer(4) {
	// landing page
	#landing {
		&-hero {
			padding-top: rhythm(16);
			padding-bottom: rhythm(5);
			background-position: center right;
			&-title {
				font-size: 26px;
			}
		}
		&-steps {
			padding-top: rhythm(5);
			padding-bottom: rhythm(5);
			&-sup {
				text-align: center;
			}
			&-title {
				margin-bottom: rhythm(3);
			}
			&-content {
				padding-bottom: 0;
				text-align: center;

			}
			@at-root {
				.landing-step {
					padding-bottom: 0;

					&:before {
						position: static;
						display: block;
						margin: 0 auto rhythm(1);
					}
				}
			}
			&-request {
				margin: auto;
			}
		}
		&-integrations {
			margin-bottom: rhythm(2);
			&-more {
				margin-bottom: 0;
			}
		}
		&-features {
			padding-bottom: rhythm(7);
		}
		@at-root {
			.landing-integration {
				width: 180px;
				margin: 0 auto rhythm(1);
			}
		}
		&-benefits {
			padding-top: rhythm(7);
			&-title-group {
				margin-bottom: rhythm(2);
			}
			&-intro {
				font-size: 16px;
				line-height: 28px;
			}
		}
		@at-root {
			.landing-tab-right {
				padding-right: 0 !important;
				padding-left: 0 !important;
			}
			.landing-testimonial {
				padding: rhythm(7) 30px;
			}
		}
		.carousel-inner .item {
			min-height: auto;
		}

	}
	// end landing page

	// product page
	.product-hero {
		padding: rhythm(7) 0;
		text-align: center;
		&-title {
			margin-bottom: rhythm(2);
			font-size: 26px;
			line-height: 40px;
		}
		&-btn {
			display: block;
		}
		&-list {
			text-align: left;
			> li {
				font-size: 16px;
				line-height: 24px;
			}
		}
	}
	// nav section
	#product {
		&-nav {
			background: $grey-light-background;
			&-links {
				> li {
					float: none;
					margin-right: 0;
					line-height: 56px;
					text-align: center;
				}
			}
		}
		&-overview {
			padding: rhythm(7) 0;
		}
		&-tabs-controller > li {
			float: none;
			width: 100%;
			height: rhythm(7);
			border-left: none;
			> a {
				font-size: 16px;
				line-height: rhythm(7);
				padding: 0 0 0 20px;
				&:before {
					width: 32px;
					height: 32px;
					font-size: 18px;
					line-height: 35px;
					margin-top: 12px;
				}
			}
		}
	}
	.product-overview-feature {
		margin-bottom: rhythm(5);
		&:last-child {
			margin-bottom:0;
		}
	}
	.product-tab-content {
		padding-top: rhythm(7);
		padding-bottom: rhythm(7);
	}
	.product-benefit-entry {
		padding: rhythm(7) 0;

		p:last-child{
			margin-bottom:0;
		}
	}
	#product #product-single-benefit-3 {
		padding-bottom: 0;
		.product-image {
			margin-left: 0;
		}
	}
	#product-corporate-benefit-3 .product-image{
		margin-left: 50px!important;
		margin-bottom: 0;
	}
	.product-integration-logo {
		width: 100%;
	}
	#product-testimonial{
		padding-top: rhythm(7);
		padding-bottom: rhythm(7);
	}
	#price-faq {
		padding-top: rhythm(7);
		.container-title{
			text-align: left;
		}
	}
	#price-faq-entries{
		margin-bottom: rhythm(7);
	}
	// end product page

	// start pricing page
	#price-plan {
		padding: rhythm(7) 0;

		&-title, &-save20 {
			margin-bottom: rhythm(2);
		}
		.price-card {
			margin-bottom: rhythm(5);
		}
		&-more {
			margin-bottom: 0;
		}
	}
	#price-features {
		padding-top: rhythm(7);
	}
	#price-testimonial{
		padding: rhythm(7) 0;
	}
	// .price-card-features
	.price-card-features-item {
		padding-left: 15px !important;
		padding-right: 15px !important;
		font-size: 14px;
		&.has-tooltip {
			padding-right: 40px;
			&:after {
				right: 10px;
			}
		}
	}

	.price-card-title {
		padding: 16px 0 10px;
	}

	.price-features-item--left,
	.price-features-item--right {
		padding-left: 20px;
		text-align: center;
		&:before {
			position: static;
			display: block;
			margin: 0 auto rhythm(3);
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
	.enquiry-panel {
		padding: 30px 10px;
		> p {
			font-size: 18px;
			line-height: 32px;
		}
	}
	.price-faq-entry--left,
	.price-faq-entry--right {
		margin-bottom: rhythm(5);

		&:last-child {
			margin-bottom: 0;
		}
	}
	// end pricing page

	// customers page
	.customers-hero-content {
		font-size: 18px;
		line-height: 28px;
	}
	#customers-cases-controller {
		#icon-filter-all:before {
			margin-bottom:5px!important;
		}
	}
	.customers-hero-testimonial {
		padding: rhythm(7) 20px;
	}
	#customers-hero-indicators-wrap {
		height:0;
		bottom:rhythm(4);
	}
	#customers-cases {
		padding: rhythm(7) 0;
		&-controller {
			margin-bottom: rhythm(5);
		}
		.case-card {
			margin-bottom: rhythm(5);
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	#customers-testimonials {
		padding-top: rhythm(7);
		&-controller {
			margin-bottom: rhythm(5);
		}
	}
	// end customers page

	// about page
	#about {
		&-hero, &-team, &-mission, &-value {
			padding: rhythm(7) 0;
		}
		&-company {
			padding-top: rhythm(7);
			&-content {
				columns: 1;
			}
		}
		&-mission {
			padding-bottom: 0;
			&-btn {
				margin-bottom: rhythm(5);
			}
		}
	}
	.about-company-third:last-child > .about-company-stat{
		margin-bottom: 0;
	}
	.about-team-members, .webinar-team-members {
		padding-top: rhythm(2);
	}
	.member {
		display: block;
		width: 100%;
		margin-bottom: rhythm(5);
		&:last-child {
			margin-bottom: 0;
		}
		&-dropdown {
			height: 437px;
		}
	}
	.about-value-item--left,
	.about-value-item--right {
		padding-left: 20px;
		text-align: center;
		&:before {
			position: static;
			display: block;
			margin: 0 auto rhythm(3);
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
	// end about page

	// contact page
	#contact {
		&-main {
			padding: rhythm(7) 0;
		}
		&-title-group {
			margin-bottom: rhythm(7);
			text-align: left;
		}
		&-message-intro {
			margin-bottom: rhythm(3.75);
			font-size: 16px;
			line-height: 28px;
		}
		&-offices {
			padding: rhythm(7) 0;
			background: $grey-dark-background;
			> .container-title {
				text-align: left;
			}
			&-title {
				margin-bottom: 0;
			}
		}
	}
	.contact-office {
		&-photo {
			position: static;
			width: 100%;
			height: 225px;
			border-radius: 3px 3px 0 0;
		}
		&-content {
			float: none;
			padding-left: 20px;
		}
	}
	.contact-support-item {
		padding-left: 0;

		&:before {
			display: none;
		}
	}
	// end contact page

	// Integrations page
	#integrations {
		&-content {
			padding-top: rhythm(7);
		}
		.integrations-card {
			margin-bottom: rhythm(5);
			&:last-child {
				margin-bottom: 0;
			}
		}
		&-controller {
			margin-bottom: rhythm(5);
			li.active{
				background:$grey-2!important;
			}
			#icon-filter-integrations:before {
				margin-bottom:11px!important;
			}
			#icon-filter-accounting:before {
				margin-bottom:13px!important;
			}
			#icon-filter-cloud:before {
				margin-bottom:11px!important;
			}
		}
	}
	// end integrations page

	// Add-on detail
	#addon {
		&-hero-left {
			padding: rhythm(7) 20px rhythm(5);
		}
		&-hero-integration {
			&-logo {
				&:before, &:after {
					display: block;
					float: none;
					margin: 0 auto rhythm(2);
				}
			}
			> img {
				float: none;
				display: block;
				margin: auto;
			}
		}
		&-hero-right > .product-image{
			max-width: 100%;
		}
		&-steps, &-testimonials {
			padding: rhythm(7) 0;
		}
		.addon-step {
			margin-bottom: rhythm(5);
			&:last-child {
				margin-bottom: 0;
			}
		}
		&-connecting {
			padding-top: rhythm(7);
			> .container-9 {
				margin-bottom: rhythm(7);
			}
			@at-root .addon-connecting-left > h3 {
				margin-top: 0;
			}
		}
	}
	// webinar page
	#webinar {
		&-hero, &-events, &-team {
			padding: rhythm(7) 0;
		}
		&-events-list {
			margin-bottom: 0;
		}
	}
	.webinar-event {
		padding-left: 15px;
		padding-right: 15px;
		&-item {
			> span {
				width: 60px;
			}
		}
		> .btn-green {
			margin-left: 60px;
		}
	}
	// end webinar page

	// partners page
	#partners {
		&-overview {
			padding: rhythm(7) 0 0;
		}
		&-hero {
			text-align: left;
		}
		.partners-overview-intro{
			margin-bottom:  0;
		}
	}

	// Quote page
	#quote {
		&-main {
			padding: rhythm(7) 0;
		}
		&-trust {
			padding-top: rhythm(7);
		}
		&-testimonial {
			padding: rhythm(7) 0;
			.testimonial-wrap {
				background: none;
				border-radius: 0;
				padding: 0;
			}
		}
	}
}
