$carousel-timing: .5s ease-in-out;

.carousel {
	&-controller {
		position: relative;
		max-width: 530px;
		margin: 0 auto rhythm(3);
	}
	// carousel left and right button
	&-control {
		position: absolute;
		top: 66px;
		z-index: 10;
		opacity: .5;
		transition: opacity .2s ease-in-out;
		&:hover {
			opacity: 1
		}
		&.left {
			left: 34px;
			@include SVGsprite('Icon--arrow-left');
		}
		&.right {
			right: 34px;
			@include SVGsprite('Icon--arrow-right');
		}
	}

	// content
	&-inner {
		position: relative;
		overflow: hidden;
		width: 100%;
		> .item {
			position: relative;
			transition: transform $carousel-timing;
			display: none;
		}
		> .active, > .prev, > .next {
			display: block;
		}
		> .prev, > .next {
			position: absolute;
			top: 0;
			width: 100%;
		}
	 > .next, > .active.right {
			transform: translate3D(100%, 0, 0);
		}
		> .prev, > .active.left {
			transform: translate3D(-100%, 0, 0);
		}
		> .active, > .prev.right, > .next.left {
			transform: translate3D(0, 0, 0);
		}
	}

	// indicators
	&-indicators:not(#customers-hero-indicators) {
		//padding-top: rhythm(1);
		margin-bottom: rhythm(3);
		text-align: center;
		list-style: none;
		// disable indicators click event
		position: relative;
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
		> li {
			display: inline-block;
			margin: 0 2px;
			width: 8px;
			height: 8px;
			border-radius: 4px;
			background: $landing-tab-border;
			&.active {
				background: $green;
			}
		}
	}
}

// testimonial avatar
.testimonial {
	&-avatars {
		position: relative;
		width: 460px;
		margin: auto;
		overflow: hidden;
		// &:before, &:after {
		// 	content: "";
		// 	position: absolute;
		// 	top: 0;
		// 	bottom: 0;
		// 	z-index: 1;
		// 	width: 50px;
		// }
		// &:before {
		// 	left: 0;
		// 	background-image: linear-gradient(-90deg, rgba(255,255,255,0.00) 0%, #F5F7F7 100%);
		// }
		// &:after {
		// 	right: 0;
		// 	background-image: linear-gradient(90deg, rgba(255,255,255,0.00) 0%, #F5F7F7 100%);
		// }
	}
	&-avatar-list {
		display: block;
		width: 2000px;
		margin-left: -120px;
		@include clearfix;
	}
	&-avatar {
		float: left;
		margin: 0 -10px;
		width: 160px;
		height: 160px;
		border-radius: 50%;
		opacity: .3;
		transform: scale(.5, .5) translateZ(0);
		transition: all $carousel-timing;
		cursor: pointer;
		overflow: hidden;
		> img {
			display: block;
			width: 100%;
		}
		&:hover {
			opacity: .5;
		}
		&.active {
			opacity: 1;
			transform: scale(1, 1) translateZ(0);
		}
	}
	// background
	&-wrap {
		padding: rhythm(6) 50px;
		background-image: linear-gradient(0deg, rgba(251,252,252,0.00) 0%, #FFFFFF 100%);
		border-radius: 3px;
	}
}

// testimonial content and autor
.testimonial {
	user-select: none;
	&-content {
		font-size: 22px;
		line-height: 32px;
		text-align: center;
		color: $text-black-color;
	}
	&-author {
		line-height: 20px;
		text-align: center;
		color: $text-light-color;
		//margin-bottom: rhythm(3);
		font-size: 14px;

		strong {
			color: $text-grey-color;
			display: block;
			font-size: 16px;
			margin-bottom: rhythm(1);
		}
	}
}


$avatar-ratio-slice-1: 80/122;

@include slicer(1) {
	.carousel {
		&-controller {
			margin-bottom: rhythm(2);
		}
		&-indicators:not(#customers-hero-indicators) {
			margin-bottom: rhythm(2);
		}
		&-control {
			top: 48px;
			&.left {
				left: 10px;
			}
			&.right {
				right: 10px;
			}
		}
	}

	.testimonial {
		// avatar
		&-avatars {
			width: 380px;
		}
		&-avatar-list {
			margin-left: -115px;
		}
		&-avatar {
			margin: 0;
			width: 122px;
			height: 122px;
			transform: scale($avatar-ratio-slice-1, $avatar-ratio-slice-1) translateZ(0);
		}

		// content
		&-content {
			font-size: 18px;
			line-height: 32px;
		}
	}
}

@include slicer(2) {
	.carousel {
		&-controller {
			max-width: 420px;
		}
	}
}

@include slicer(4) {
	.testimonial-avatars {
		width: 140px;
	}
	.testimonial-avatar-list {
		margin-left: -230px;
	}
	.testimonial-author {
		line-height: 28px;
	}
}
