#partners {
	&-hero {
		position: relative;
		@include heroBG('partner');
	}

	// overview section
	&-overview {
		padding-top: rhythm(14.5);
		padding-bottom: rhythm(3);
		background: $white;
		&-title-group {
			margin-bottom: 0;
		}
		&-title {
			max-width: 900px;
			margin: 0 auto rhythm(1.5);
		}
		&-intro {
			max-width: 900px;
			margin: auto;
		}
	}

	&-enquiry{
		margin-top: rhythm(5);
	}

	// benefits section
	// #product-benefits {
		.product-benefit {
			&-entry {
				position: relative;
			}
			&-entry:nth-child(2n-1) {
				background: $white;
			}
			&-entry:nth-child(2n) {
				background: $grey-dark-background;
			}
		}
	// }
}