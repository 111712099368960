#webinar {
	p > a {
		@extend %transform-links;
	}
	&-hero {
		position: relative;
		padding-top: rhythm(12.5);
		padding-bottom: rhythm(12.5);
		@include heroBG('webinar');
		&-title {
			margin-bottom: rhythm(2);
		}
		&-intro {
			@extend .title-dark-intro;
			margin-bottom: rhythm(4);
		}
		&-content {
			> p {
				color: $text-grey-color;
				> strong {
					font-weight: normal;
					color: $text-dark-color;
				}
			}
			> .link-green {
				display: inline-block;
				margin-top: rhythm(1.5);
			}
		}
	}
	&-events {
		padding-top: rhythm(15);
		&-title {
			margin-bottom: rhythm(1.5);
		}
		&-list {
			max-width: 940px;
			width: 100%;
			margin: 0 auto rhythm(10);
			padding-left: 20px;
			padding-right: 20px;
			@include clearfix;
		}
		@at-root {
			.webinar {
				float: left;
				width: 50%;
				border: 1px solid $grey-light-border;
				overflow: hidden;
				&:first-child {
					border-radius: 3px 0 0 3px;
					border-right: none;
				}
				&:last-child {
					border-radius: 0 3px 3px 0;
				}
				> li {
					border-bottom: 1px solid $grey-light-border;
					&:last-child {
						border-bottom: none;
					}
				}
				&-title {
					padding: rhythm(4) 0 rhythm(2.75);
					font-size: 22px;
					line-height: 32px;
					text-align: center;
					background: $grey-light-background;
				}
				&-event {
					padding: rhythm(3.5) 48px rhythm(3);
					&-item {
						margin-bottom: rhythm(2);
						color: $text-dark-color;
						overflow: hidden;
						> span {
							float: left;
							width: 86px;
							color: $text-grey-color;
						}
						> small {
							font-size: 14px;
							color: $text-grey-color;
						}
					}
					> .btn-green {
						display: inline-block;
						margin-top: rhythm(0.5);
						margin-left: 86px;
						padding: 15px 24px;
						cursor: pointer;
					}
				}
			}
		}
	}
	&-team {
		padding-top: rhythm(15);
		padding-bottom: rhythm(5);
		background: $grey-light-background;
		@at-root {
			.webinar-team-members {
				@extend .about-team-members;
				padding-top: 0;
			}
		}
	}
}
