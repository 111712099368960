#customers {
	// hero section
	&-hero {
		position: relative;
		background: $grey-dark-background;
		> .carousel-inner > .item {
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
		}
	}
	@at-root {
		.customers-hero {
			&-testimonial {
				padding-top: rhythm(12);
				padding-bottom: 120px;
			}
			&-logo {
				height: 88px;
				margin-bottom: rhythm(2);
				background: white;
			    border-radius: 3px;
			    width: 180px;
			    text-align: center;
				> img {
					display: block;
					height: 100%;
				}
			}
			&-content {
				position: relative;
				font-size: 28px;
				line-height: 41px;
				margin-bottom: rhythm(2.5);
				&:before {
					content: "“";
					position: absolute;
					top: 0;
					left: -13px;
				}
			}
			&-author {
				margin-bottom: rhythm(3.5);
				line-height: 24px;
				color: $text-light-color;
				> strong {
					font-weight: normal;
					color: $text-grey-color;
				}
			}
			&-btn {
				display: block;
				width: 210px;
				height: 56px;
				font-size: 18px;
				line-height: 56px;
			}
		}
	}
	&-hero-indicators-wrap {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 92px;
	}
	&-hero-indicators {
		list-style: none;
		@include clearfix;
		> li {
			float: left;
			margin-right: 7px;
			width: 12px;
			height: 12px;
			border-radius: 6px;
			background: $landing-tab-border;
			cursor: pointer;
			&.active {
				background: $green;
			}
		}
	}

	// cases section
	&-cases {
		padding-top: rhythm(14.5);
		padding-bottom: rhythm(9);
		&-title {
			margin-bottom: rhythm(1.5);
		}
		&-intro {
			@extend .title-intro;
		}
		&-controller {
			margin: 0 auto rhythm(9);
			max-width: 936px;
			> li {
				// width: 25%; // 4 tabs
				width: 33%;  // 3 tabs
				padding-left: 20px;
				line-height: 44px;
				cursor: pointer;
			}
		}
	}

	// testimonial section
	&-testimonials {
		padding-top: rhythm(14.75);
		&-title {
			margin-bottom: rhythm(1.5);
		}
		&-intro {
			@extend .title-intro;
		}
		&-controller {
			margin: 0 auto rhythm(10);
			max-width: 936px;
			> li {
				width: 25%;
			}
		}
		&-content {
			position: relative;
			padding-bottom: rhythm(5);
		}
	}
}

// custerms testimonial
.customers-testimonial {
	margin-bottom: rhythm(5);
	&-content {
		position: relative;
		min-height: 210px;
		margin-bottom: 24px;
		padding: 48px 20px;
		text-align: center;
		color: $text-grey-color;
		background: $grey-dark-background;
		&:after {
			content: "";
			position: absolute;
			bottom: -10px;
			left: 50%;
			margin-left: -10px;
			width: 0;
			height: 0;
			border-top: 10px solid $grey-dark-background;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
		}
	}
	&-avatar {
		display: block;
		width: 80px;
		height: 80px;
		margin: 0 auto rhythm(1.5);
		border-radius: 50%;
	}
	&-author {
		font-size: 14px;
		text-align: center;
		color: $text-light-color;
		> strong {
			display: block;
			margin-bottom: rhythm(1.25);
			font-weight: normal;
			color: $text-grey-color;
		}
		> span {
			display: block;
			color: $text-light-color;
		}
	}
}

.customers-tab {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	transition: all .5s ease-in-out;
	@include clearfix;
	&.fade {
		opacity: 0;
		visibility: hidden;
		transform: translateY(30px);
		&.in {
			opacity: 1;
			visibility: visible;
			transform: translateY(0);
		}
	}
}
