#integrations {
	&-content {
		padding-top: rhythm(15);
		padding-bottom: rhythm(15);
		background-image: linear-gradient(0deg, rgba(251,252,252,0.00) 0%, #F5F7F7 100%);

		a {
			@extend %transform-links;
		}
	}
	&-intro {
		font-size: 18px;
		line-height: 32px;
		color: $text-gloomy-color;
	}

	&-controller {
		width: 100%;
		margin-bottom: rhythm(10);
		> li {
			width: 20%;
			cursor: pointer;
		}
	}

	@at-root {
		.integrations-card {
			display: none;
			margin-bottom: rhythm(6);
			&-logo, &-title {
				height: 90px;
				background: $grey-light-background;
				border: 1px solid $grey-light-border;
				border-radius: 3px 3px 0 0;
			}
			&-logo > img {
				display: block;
				margin: auto;
				height: 100%;
			}
			&-title {
				padding: 20px;
				font-size: 22px;
				line-height: 48px;
			}
			&-content {
				padding: 20px;
				color: $text-grey-color;
				border: 1px solid $grey-light-border;
				border-top: none;
				border-radius: 0 0 3px 3px;
				background: $white;
				> span {
					float: left;
					margin-bottom: rhythm(2);
					padding: 0 10px;
					font-size: 14px;
					line-height: 32px;
					background: $grey-dark-background;
					border-radius: 3px;
				}
			}
			&-text {
				clear: both;
				> strong {
					font-weight: normal;
					color: $text-dark-color;
				}
			}
			&-more {
				line-height: 48px;
				border: none;
				&:hover {
					text-decoration: underline;
				}
			}
			&-btn {
				display: inline-block;
				line-height: 48px;
			    margin-bottom: 14px;
			}
		}
		.integrations-api {
			> .integrations-card-content {
				border-color: $green;
				> span {
					color: $text-dark-color;
					background: $green-2;
				}
				#integration-request {
					color: $white;
				    text-align: center;
				    border:none;
				    padding: 0 24px;
				    @extend %dim-background;
				}
			}
			> .integrations-card-title {
				background: $green-3;
				border: 1px solid $green;
				border-bottom: 1px solid $green-1;
			}
		}
	}
}
