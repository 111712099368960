// rebar default settings
$mobile-first: false;
$max-width: 1485px; // 1440px + 45px (container paddings)
$breakpoints: 1670px 1400px 1000px 680px;
$gutters: 45px 30px 30px 40px 40px;

// containers
$container: (
	".container-11--gutter": (
		width: $max-width 1200px 100%,
		gutter: true
	),
	".container-11": (
		width: $max-width 1200px 100%
	),
	".container-9--gutter": (
		width: (9/11 * $max-width) 1200px 100%,
		gutter: true
	),
	".container-9": (
		width: (9/11 * $max-width) 1200px 100%
	)
);

// grids
$grid: (
	// nav grids
	"#nav-dropdown-left": (
		width: 3/11 null null 100%
	),
	"#nav-dropdown-right": (
		width: 5/11 null 7/11
	),
	// landing page grids
	"#landing-hero-grid": (
		width: 765px null 600px 100%
	),
	"#landing-steps-content": (
		width: 5/11 1/2 2.5/5 100%,
		offset-right: 1/11 0,
		float: right null left none
	),
	".landing-features-item": (
		width: 1/3 null 1/2 100%,
		gutter-left: null 45px/2,
		gutter-right: null 45px/2
	),
	".landing-integration-item": (
		width: 1/3 null null null 1,
		gutter-left: null 45px/2,
		gutter-right: null 45px/2
	),
	".landing-tab-left": (
		width: 5/11 4.5/8 1/2 100%,
		gutter-left: null null 30px,
		gutter-right: 80px 80px
	),
	".landing-tab-right": (
		width: 5/11 3.5/8 1/2 100%
	),
	// pricing page grids
	".price-features-item--left, .price-faq-entry--left, .about-value-item--left": (
		width: 1/2 null null 1,
		gutter-right: 45px
	),
	".price-features-item--right, .price-faq-entry--right, .about-value-item--right": (
		width: 1/2 null null 1,
		gutter-left: 45px
	),
	// product page grids
	".product-hero-content": (
		width: 6/11 null null 1
	),
	".product-overview-feature": (
		width: 1/4 null null 1
	),
	".product-benefit-content": (
		width: 5/9 null null 1,
		float: none
	),
	".product-benefit-image": (
		width: 4/9 null null 1,
		float: none
	),
	".product-tab-content": (
		width: 5/9 null null 1
	),
	// customers page grids
	".customers-hero-testimonial": (
		width: 6/11 null null 1
	),
	".case-card, .customers-testimonial, .integrations-card": (
		width: 1/3 null null 1/2 1
	),
	// about page prids
	"#about-hero-content": (
		width: 5/11 null null 1
	),
	"#about-company-title-group": (
		width: 2/3 null 1
	),
	"#about-company-content": (
		width: 1
	),
	".about-company-third": (
		width: 1/3 null null 1
	),
	// contact page grids
	".contact-grid--left": (
		width: 5/11 null null 1
	),
	".contact-grid--right": (
		width: 5/11 null null 1,
		float: right null null left
	),
	// quote page grids
	".quote-grid--left": (
		width: 5/11 null null 1
	),
	".quote-grid--right": (
		width: 5/11 null null 1,
		float: right null null left
	),
	// addon page
	"#addon-hero-left": (
		width: 6/11 null null 1
	),
	"#addon-hero-right": (
		width: 5/11 null null 1
	),
	".addon-step": (
		width: 1/3 null null 1
	),
	".addon-connecting-left": (
		width: 5/9 null null 1
	),
	".addon-connecting-right": (
		width: 4/9 null null 1,
		float: right
	),
	// webinar grids
	"#webinar-hero-left": (
		width: 6/11 null null 1
	),
	// footer grids
	".footer-grid-2": (
		width: 2/9 null 1/4.75 1/4 1
	)
);

@import "rebar/rebar";


@include slicer(2) {
	.container-11--gutter, .container-11, .container-9, .container-9--gutter {
		max-width: 1200px;
	}
	#landing-features > .container-9 {
		max-width: 900px;
	}
}

@include slicer(3) {
	.container-11--gutter, .container-11, .container-9, .container-9--gutter {
		max-width: 800px;
	}
	#landing-features > .container-9 {
		max-width: 570px;
	}
}
