.download-form {
	width: 100%;
	&-group {
		position: relative;
		height: 56px;
		font-size: 16px;
		box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.05);
		&.dark-shadow {
			box-shadow: 0px 0px 40px 0px rgba(0,0,0,0.10);
		}
	}
	&-input {
		float: left;
		width: percentage(2/3);
		padding-left: 62px;
		height: 100%;
		color: $text-dark-color;
		background: $white;
		border-top: 2px solid $white;
		border-left: 2px solid $white;
		border-bottom: 2px solid $white;
		border-right: none;
		outline: none;
		border-radius: 3px 0 0 3px;
		&:focus {
			border-color: $landing-tab-active-border;
		}
		&.error {
			border-color: $red;
		}
	}
	&-submit {
		float: right;
		width: percentage(1/3);
		height: 100%;
		border: none;
		border-radius: 0 3px 3px 0;
	}
	&-text {
		clear: both;
		margin: 0;
		text-align: right;
		color: $text-gloomy-color;
	}
	&-at {
		position: absolute;
		top: 12px;
		left: 20px;
		z-index: 1;
		@include SVGsprite('Icon--at');
	}
}


@include slicer(2) {
	.download-form {
		&-group {
			height: 56px;
		}
		&-text {
			font-size: 14px;
			line-height: 18px;
		}
		&-at {
			top: 12px;
		}
	}
}


@include slicer(4) {
	.download-form {
		&-group {
			height: auto;
			box-shadow: none;
			&.dark-shadow {
				box-shadow: none;
			}
		}
		&-input {
			border-right: 2px solid $white;
			text-align: center;
			padding-left:0;
		}
		&-input, &-submit {
			float: none;
			width: 100%;
			height: 56px;
			margin-bottom: rhythm(1);
			border-radius: 3px;
		}
		&-text {
			text-align: center;
		}
		&-at {
			top: 12px;
		}
	}
}
