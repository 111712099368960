.form {
	&-content {
		position: relative;
	}
	&-entry {
		position: relative;
		margin-bottom: rhythm(2);
		background: $white;
		@include clearfix;
		&.focus {
			> .form-placeholder {
				transform: translateY(-8px);
				font-size: 14px;
				color: $green;
				z-index: 2;
			}
		}
		&.valid {
			background: $grey-light-background;
			&:before {
				content: "";
				position: absolute;
				top: 18px;
				right: 20px;
				@include SVGsprite('Icon--tick');
			}
			> .form-input {
				border-color: $grey-light-border;
			}
			> .form-placeholder {
				color: $text-light-color;
			}
		}
		&.selected {
			background: $grey-light-background;
			&:before {
				content: "";
				position: absolute;
				top: 18px;
				right: 80px;
				@include SVGsprite('Icon--tick');
			}
			> .fancy-select > .trigger {
				border-color: $grey-light-border;
			}
			> .form-placeholder {
				color: $text-light-color;
			}
		}
		&.invalid {
			background: $error-background;
			&:before {
				content: "";
				position: absolute;
				top: 22px;
				right: 20px;
				@include SVGsprite('Icon--error');
			}
			> .form-input {
				border-color: $error-border;
			}
			> .form-placeholder {
				color: $red;
			}
		}

		@at-root label {
			font-size: 18px;
			line-height: 28px;
			color: $text-7;
			margin: rhythm(4) 0 rhythm(1);
			display: block;
			background: none;
		}
	}
	&-input {
		position: relative;
		z-index: 2;
		display: block;
		width: 100%;
		height: 64px;
		font-size: 16px;
		line-height: 30px;
		padding: 28px 20px 6px;
		color: $text-dark-color;
		background: transparent;
		border: 2px solid $landing-tab-border;
		border-radius: 3px;
		outline: none;
	}
	&-textarea {
		display: block;
		width: 100%;
		min-height: 200px;
		line-height: 28px;
		padding: 28px 20px 6px;
		color: $text-dark-color;
		background: transparent;
		border: 2px solid $landing-tab-border;
		border-radius: 3px;
		resize: vertical;
		outline: none;
	}
	&-placeholder {
		position: absolute;
		top: 22px;
		left: 20px;
		z-index: 2;
		font-size: 18px;
		color: $text-light-color;
		transition: all .15s linear;
		&.active {
			transform: translateY(-8px);
			font-size: 14px;
		}
		> em {
			font-size: 14px;
			font-style: normal;
			color: $text-2;
		}
	}
	&-submit {
		position: relative;
		display: block;
		width: 100%;
		height: 56px;
		font-size: 18px;
		line-height: 56px;
		text-align: center;
		padding: 0 40px;
		overflow: hidden;
		transition: width .35s ease-in-out;
		transform: translateZ(0);
		&:before {
			content: attr(data-fail);
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			color: $white;
			background: $red;
			text-align: center;
			opacity: 0;
			transition: opacity .35s ease-in-out;
			transform: translateZ(0);
		}
		&.fail {
			width: 260px;
			&:before {
				opacity: 1;
			}
		}
	}

	// message
	&-success {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: -100px;
		z-index: -1;
		padding: rhythm(6) 40px 0;
		text-align: center;
		background: #fff;
		border-radius: 4px;
		opacity: 0;
		transform: rotateY(180deg);
		transition: all .35s ease-in-out;
		&.active {
			z-index: 10;
			opacity: 1;
			transform: rotateY(0deg);
		}
		&-close {
			position: absolute;
			top: rhythm(2.5);
			right: rhythm(2.5);
			@include SVGsprite('Icon--close-grey');
		}
		&-title {
			margin-bottom: rhythm(2);
			font-weight: normal;
			&:before {
				content: "";
				display: block;
				margin: 0 auto rhythm(2);
				@include SVGsprite('Icon--form-success');
			}
		}
		&-content {
			color: $text-grey-color;
		}
	}
	&-fail {
		display: none;
		position: relative;
		margin-top: rhythm(2);
		padding: 18px 20px 15px 55px;
		background: $error-background;
		border: 2px solid $error-border;
		border-radius: 3px;
		&:before {
			content: "";
			position: absolute;
			top: 20px;
			left: 20px;
			@include SVGsprite('Icon--error');
		}
		&-content {
			margin-bottom: 0;
			color: $text-dark-color;
		}
	}
}


// fancy select
.fancy-select {
	position: relative;
	> .trigger {
		width: 100%;
		height: 64px;
		font-size: 16px;
		line-height: 30px;
		padding: 28px 20px 6px;
		color: $text-dark-color;
		background: transparent;
		border: 2px solid $landing-tab-border;
		border-radius: 3px;
		cursor: pointer;
		&:before {
			content: "";
			position: absolute;
			top: 2px;
			right: 2px;
			bottom: 2px;
			z-index: 1;
			width: 60px;
			height: 60px;
			background: $grey-3;
			border-radius: 0 1px 1px 0;
		}
		&:after {
			content: "";
			position: absolute;
			top: 28px;
			right: 24px;
			z-index: 2;
			width: 0;
			height: 0;
			border-top: 7px solid $text-3;
			border-left: 7px solid transparent;
			border-right: 7px solid transparent;
		}
		&.open {
			border-radius: 3px 3px 0 0;
		}
	}
	> .options {
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		z-index: 10;
		color: $text-dark-color;
		background: $white;
		border: 2px solid $landing-tab-border;
		border-top: none;
		border-radius: 0 0 3px 3px;
		opacity: 0;
		visibility: hidden;
		transform: translateY(-10px);
		transition: all .35s ease-in-out;
		> li {
			padding: 18px 20px;
			cursor: pointer;
			&.selected {
				background: $grey-light-background;
			}
			&:hover {
				background: $grey-dark-background;
			}
		}
		&.open {
			opacity: 1;
			visibility: visible;
			transform: translateY(0);
		}
	}
	+ .form-placeholder {
		left: 22px;
	}
}
