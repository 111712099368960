#about {
	&-hero {
		position: relative;
		padding-top: rhythm(15);
		padding-bottom: rhythm(15);
		@include heroBG('about');
		&-title {
			margin-bottom: rhythm(2.75);
		}
		&-intro {
			@extend .title-dark-intro;
			margin-bottom: 0;
		}
	}

	&-nav {
		@extend #product-nav;
		&-links {
			@extend #product-nav-links;
		}
	}

	&-company {
		padding-top: rhythm(14.75);
		&-title {
			margin-bottom: rhythm(1.5);
		}
		&-intro {
			margin-bottom: rhythm(3.5);
			@extend .title-intro;
		}
		&-content {
			margin-bottom: rhythm(7);
			color: $text-grey-color;
			columns: 2;
			column-gap: 90px;
		}
		@at-root {
			.about-company-stat {
				margin-bottom: rhythm(3);
				padding: rhythm(4.5) 10px;
				text-align: center;
				background: $grey-dark-background;
				> strong {
					position: relative;
					display: block;
					margin-bottom: rhythm(1);
					font-size: 45px;
					line-height: 45px;
					font-weight: normal;
					&:after {
						content: "+";
						position: absolute;
						font-size: 32px;
						color: $text-2;
					}
				}
				> span {
					display: block;
					color: $text-light-color;
					&:before {
						content: "";
						display: block;
						margin: 0 auto rhythm(2.25);
						width: 45px;
						height: 2px;
						background: $green;
					}
				}
			}
		}
	}

	&-team {
		padding-top: rhythm(12);
		padding-bottom: rhythm(5);
		@at-root {
			.about-team-title {
				margin-bottom: rhythm(1.5);
			}
			.about-team-members {
				padding-top: rhythm(7.25);
				padding-bottom: rhythm(3);
				text-align: center;
			}
			.member {
				position: relative;
				display: inline-block;
				margin-bottom: rhythm(7.5);
				width: 21.5%;
				&.hover {
					z-index: 300;
					> .member-avatar {
						> .member-avatar-hover {
							opacity: 1;
							&:after {
								@include SVGsprite('Icon--cross-white');
							}
						}
						> .member-avatar-active {
							opacity: 1;
							transform: scale(1, 1);
						}
						> .member-dropdown {
							opacity: 1;
							visibility: visible;
							transform: translateY(0);
						}
					}
				}
				&-avatar {
					position: relative;
					width: 225px;
					height: 225px;
					margin: 0 auto rhythm(2.25);
					background-size: cover;
					border-radius: 50%;
					&:hover {
						> .member-avatar-hover {
							opacity: 1;
						}
					}
				}
				&-avatar-hover {
					opacity: 0;
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					z-index: 5;
					border-radius: 50%;
					background-size: cover;
					overflow: hidden;
					transition: opacity .35s ease-in-out;
					&:before {
						content: "";
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						background: $green;
						opacity: 0.7;
					}
					&:after {
						content: "";
						position: absolute;
						top: rhythm(3);
						left: 0;
						right: 0;
						margin: auto;
						@include SVGsprite('Icon--info-white');
					}
				}
				&-avatar-image {
					display: block;
					width: 100%;
					border-radius: 50%;
				}
				&-avatar-active {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					z-index: 10;
					padding-top: 100px;
					border-radius: 50%;
					opacity: 0;
					transform: scale(0, 0);
					transition: all .3s ease;
				}
				&-dropdown {
					position: absolute;
					top: 50%;
					height: 458px;
					padding: 183px 20px 20px;
					z-index: -1;
					color: $text-grey-color;
					background: $grey-dark-background;
					border-radius: 0 0 120px 120px;
					overflow: hidden;
					opacity: 0;
					visibility: hidden;
					transform: translateY(-80px);
					transition: all .35s ease;
					> p {
						font-size: 14px;
						line-height: 24px;
						&:before {
							content: "";
							display: block;
							width: 20px;
							height: 2px;
							background: $green;
							margin: 0 auto 6px;
						}
					}
				}

				&-info {
					position: relative;
					z-index: 10;
					> strong {
						display: block;
						margin-bottom: rhythm(1.25);
						font-weight: normal;
						color: $text-dark-color;
					}
					> span {
						display: block;
						font-size: 14px;
						line-height: 21px;
						color: $text-light-color;
					}
				}
			}

			// member icons
			.member-social-link {
				display: inline-block;
			}
			#member-icon {
				&-twitter {
					@include SVGsprite('Icon--twitter-white');
				}
				&-linkedin {
					@include SVGsprite('Icon--linkedin-white');
				}
				&-gplus {
					@include SVGsprite('Icon--google-plus-white');
				}
			}
		}
	}

	&-mission {
		padding-top: rhythm(14.75);
		background: url('../images/about/team-bg.jpg') center top no-repeat;
		@include retina {
			background: url('../images/about/team-bg@2x.jpg') center top no-repeat;
		}
		&-title {
			margin-bottom: rhythm(.5);
		}
		&-intro {
			margin-bottom: rhythm(4.75);
		}
		&-btn {
			display: block;
			margin: 0 auto rhythm(10);
			width: 230px;
			height: 56px;
			font-size: 18px;
			line-height: 56px;
			text-align: center;
		}
	}

	&-photos {
		margin: auto;
		border-radius: 6px;
		overflow: hidden;
	}
	@at-root {
		.about-photo {
			&-grid {
				float: left;
				width: 390px;
			}
			&-big {
				width: 390px;
				background: $grey-dark-background;
				border: 1px solid $white;
			}
			&-small {
				width: 390px;
				background: $grey-dark-background;
				border: 1px solid $white;
			}
			&-big, &-small {
				> img {
					display: block;
					width: 100%;
				}
			}
		}
	}

	&-value {
		padding-top: rhythm(15);
		padding-bottom: rhythm(8);
		&-title {
			margin-bottom: rhythm(1.5);
		}
		&-intro {
			margin-bottom: rhythm(11);
		}
	}
}

.about-value {
	&-row {
		@include clearfix;
	}
	&-item--left,
	&-item--right {
		position: relative;
		padding-left: 160px;
		margin-bottom: rhythm(5);
		color: $text-grey-color;
		&:before {
			content: "";
			position: absolute;
			top: -10px;
			left: 25px;
		}
		> h3 {
			font-weight: 400;
			margin-bottom: rhythm(1.5);
		}
	}
	&--right {
		padding-left: 160px + 21;
		&:before {
			left: 46px
		}
	}
}
