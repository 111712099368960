$dropdown-timing: .3s ease-in-out;

// language dropdown
.language {
	position: relative;
	float: right;
	&-trigger {
		width: 48px;
		height: 48px;
		font-size: 15px;
		line-height: 44px;
		text-align: center;
		color: $text-grey-color;
		border: 2px solid;
		border-radius: 3px;
	}
	&.open-to-top > &-trigger {
		background: $grey-light-background;
		border-color: $landing-tab-border;
	}
	&.open-to-bottom > &-trigger {
		background: rgba(255,255,255,.85);
		border-color: transparent;
	}
	&-dropdown {
		display: none;
		position: absolute;
		right: 0;
		z-index: 100;
		width: 270px;
		border-radius: 3px;
		border: 2px solid $grey-light-border;
		> li {
			color: $text-dark-color;
			background: $white;
			border-bottom: 1px solid $grey-light-border;
			&:hover {
				background: $grey-dark-background;
			}
			&.active {
				position: relative;
				color: $text-black-color;
				background: $white !important;
				&:after {
					content: "";
					position: absolute;
					top: 11px;
					right: 11px;
					@include SVGsprite('Icon--tick');
				}
			}
			&:first-of-type {
				border-radius: 1px 1px 0 0;
			}
			&:last-of-type {
				border-bottom: none;
				border-radius: 0 0 1px 1px;
			}
			> a {
				display: block;
				padding-left: 24px;
				line-height: 47px;
			}
		}
	}
	// language dropdown open styles
	&.open {
		> .language-trigger {
			color: $text-black-color;
			border-color: $grey-light-border;
		}
		&.open-to-top > .language-dropdown {
			bottom: calc(100% + 15px);
			display: block;
			&:before {
				content: "";
				position: absolute;
				bottom: -7px;
				right: 15px;
				z-index: 2;
				border-top: 7px solid $white;
				border-left: 7px solid transparent;
				border-right: 7px solid transparent;
			}
			&:after {
				content: "";
				position: absolute;
				bottom: -10px;
				right: 12px;
				z-index: 1;
				border-top: 10px solid $grey-light-border;
				border-left: 10px solid transparent;
				border-right: 10px solid transparent;
			}
		}
		&.open-to-bottom > .language-dropdown {
			top: calc(100% + 15px);
			display: block;
			&:before {
				content: "";
				position: absolute;
				top: -7px;
				right: 15px;
				z-index: 2;
				border-bottom: 7px solid $white;
				border-left: 7px solid transparent;
				border-right: 7px solid transparent;
			}
			&:after {
				content: "";
				position: absolute;
				top: -10px;
				right: 12px;
				z-index: 1;
				border-bottom: 10px solid $grey-light-border;
				border-left: 10px solid transparent;
				border-right: 10px solid transparent;
			}
		}
	}
}

// telephone dropdown
.telephone {
	position: relative;
	float: right;
	margin-left: 10px;
	&-trigger {
		position: relative;
		z-index: 11;
		float: left;
		width: 270px;
		height: 48px;
		padding-left: 24px;
		text-align: left;
		line-height: 44px;
		color: $text-grey-color;
		border: 2px solid;
		border-radius: 3px;
		&:before {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			z-index: 1;
			width: 44px;
			height: 44px;
			background: $grey-2;
		}
		&:after {
			content: "";
			position: absolute;
			top: 19px;
			right: 17px;
			z-index: 2;
			width: 0;
			height: 0;
		}
	}
	&.open-to-top > &-trigger {
		background: $grey-light-background;
		border-color: $landing-tab-border;
		&:after {
			border-bottom: 6px solid $text-3;
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
		}
	}
	&.open-to-bottom > &-trigger {
		background: rgba(255,255,255,.85);
		border-color: $white;
		&:after {
			border-top: 6px solid $text-3;
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
		}
	}
	&.enquiry-dropdown > &-trigger {
		background: $white;
		border-color: $landing-tab-border;
		&:after {
			border-top: 6px solid $text-3;
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
		}
	}
	&-dropdown {
		display: none;
		position: absolute;
		left: 0;
		right: 0;
		z-index: 10;
		color: $text-dark-color;
		background: $white;
		border: 2px solid $grey-light-border;
		overflow: hidden;
		> li {
			&:hover {
				background: $grey-dark-background;
			}
			> a {
				display: block;
				padding-left: 24px;
				line-height: 48px;
			}
		}
	}
	// telephone dropdown open styles
	&.open {
		> .telephone-trigger {
			color: $text-dark-color;
			background: $white !important;
			border-color: $white !important;
			border: 2px solid $grey-light-border !important;
		}
		&.open-to-top {
			> .telephone-trigger {
				border-radius: 0 0 3px 3px;
			}
			> .telephone-dropdown {
				bottom: 100%;
				display: block;
				border-bottom: none;
				border-radius: 3px 3px 0 0;
			}
		}
		&.open-to-bottom, &.enquiry-dropdown {
			> .telephone-trigger {
				border-radius: 3px 3px 0 0;
			}
			> .telephone-dropdown {
				top: 100%;
				display: block;
				border-top: none;
				border-radius: 0 0 3px 3px;
			}
		}
	}
}

// landing page
// get app dropdown
#getapp {
	position: relative;
	user-select: none;
	text-align: left;
	&-trigger {
		position: relative;
		z-index: 10;
		height: 48px;
		margin-top: rhythm(1.75);
		padding-left: 23px;
		padding-right: 70px;
		line-height: 44px;
		color: $text-dark-color;
		background: $grey-light-background;
		border: 2px solid $landing-tab-border;
		border-radius: 3px;
		transition: all $dropdown-timing;
		&:before {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			z-index: 1;
			width: 44px;
			height: 44px;
			background: $grey-3;
		}
		&:after {
			content: "";
			position: absolute;
			top: 19px;
			right: 17px;
			z-index: 2;
			width: 0;
			height: 0;
			border-top: 6px solid $text-3;
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
		}
		&:hover {
			background: $white;
		}
	}
	&-dropdown {
		position: absolute;
		top: calc(100% - 2px);
		left: 0;
		z-index: 10;
		max-width: 360px;
		width: 100%;
		background: $white;
		border: 2px solid $grey-light-border;
		border-radius: 0 3px 3px 3px;
		opacity: 0;
		visibility: hidden;
		transform: translateY(10px);
		transition: all $dropdown-timing;
		> li {
			border-bottom: 1px solid $grey-light-border;
			&:hover {
				background: $grey-dark-background;
			}
			&:last-of-type {
				border-bottom: none;
			}
			> a {
				display: block;
				padding-left: 25px;
				height: 46px;
				line-height: 46px;
				color: $text-dark-color;
				@include clearfix;
				&:before {
					content: "";
					float: left;
					margin-right: 11px;
					margin-top: 7px;
					width: 32px;
					height: 32px;
				}
			}
		}
	}
	// get-app dropdrown open styles
	&.open {
		> #getapp-trigger {
			background: $white;
			border-color: $grey-light-border;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
		> #getapp-dropdown {
			opacity: 1;
			visibility: visible;
			transform: translateY(0);
		}
	}
}

@include slicer(4) {
	.language, .telephone {
		display: block;
		width: 100%;
		margin-bottom: rhythm(2);
	}
	.language-trigger {
		margin: auto;
	}
	.language-dropdown {
		left: 0;
		margin: auto;
		&:before {
			margin-right: -7px !important;
			right: 50% !important;
		}
		&:after {
			margin-right: -10px !important;
			right: 50% !important;
		}
	}
	.telephone-trigger {
		width: 100%;
	}
	#getapp-trigger {
		margin-left: auto;
		margin-right: auto;
	}
	#getapp-dropdown {
		right: 0;
		margin-right: auto;
		margin-left: auto;
	}
}
