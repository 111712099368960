//ios
// hero
#ios-hero {
	position: relative;
	padding-top: rhythm(6);
	padding-bottom: rhythm(6);
	@include heroBG('beta');
	&-logo {
		display: block;
		margin-bottom: rhythm(12.5);
		@include SVGsprite('Logo--text');
	}
	&-title {
		margin-bottom: rhythm(2);
		//max-width: 500px;
		font-size: 48px;
		line-height: 72px;
		font-weight: 500;
		color: $hero-title-color;
	}
	&-intro {
		margin-bottom: rhythm(5);
		@extend .title-dark-intro;
		max-width: 630px;
		font-size: 24px;
		line-height: 32px;
	}

	&-dropdowns {
		position: absolute;
		top: 0;
		right: 15px;
		z-index: 10;
	}
}

.beta-block-white{
  	padding-top: 60px;
 	padding-bottom: 120px;
	background-color: #fff;
}

.beta-block-white .quote-grid{
  	max-width: 600px;
  	margin: 0 auto;
}

// Screenshots
.screenshot {
	width: 40%;
	margin-top:	30px;
	margin-bottom: 20px
}