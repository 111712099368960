$nav-content-height: 56px;

// affix placeholder
#nav-outer {
	position: relative;
	height: 104px;
	background: $grey-light-background;
}
#nav {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1030;
	padding: rhythm(3) 0;
	font-size: 18px;
	line-height: $nav-content-height;
	background: $white;
	border-bottom: 1px solid $grey-light-border;


	// left part
	&-logo {
		float: left;
		// margin-top: 3px;
		// margin-left: -232px;
		margin: 3px 45px 0 0;
		@include SVGsprite('Logo--text');
	}
	&-links {
		float: left;
		color: $text-grey-color;
		> li {
			float: left;
			margin-right: 45px;
			> a {
				transition: color .1s linear;
				display: block;
			}
			&.active > a, &:hover > a {
				color: $text-dark-color;
			}
		}
	}

	// right part
	&-buttons {
		position: relative;
		float: right;
		color: $text-dark-color;
		> a {
			float: left;
		}
	}
	&-login-btn {
		margin: 0 30px;
		&:hover {
			color: $text-black-color;
		}
	}
	&-book-btn {
		padding: 0 32px;
		transform: translateX(0);
	}
	&-trial-btn {
		position: absolute;
		top: 0;
		right: 0;
		opacity: 0;
		z-index: -1;
		padding: 0 24px;
	}

	// dropdown
	&-dropdown-trigger {
		float: left;
		border: none;
		color: inherit;
		line-height: inherit;
		text-align: left;
		&.active, &:hover {
			color: $text-dark-color;
		}
		&:after {
			content: "";
			float: right;
			margin-top: 24px;
			margin-left: 11px;
			width: 0;
			height: 0;
			border-top: 6px solid $text-3;
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
		}
	}
	&-dropdown {
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		z-index: 5;
		padding: rhythm(6.5) 0 rhythm(8);
		background: $grey-light-background;
		border-top: 1px solid $text-1;
		border-bottom: 1px solid $text-1;
		opacity: 0;
		visibility: hidden;
		transform: translateY(-10px);
		transition: opacity $dropdown-timing, visibility $dropdown-timing, transform $dropdown-timing;
		&.open-to-top {
			top: auto;
			bottom: 100%;
		}
	}
	// dropdown opening styles
	&-product {
		float: left;
		&.open {
			> #nav-dropdown-trigger {
				color: $text-black-color;
			}
			> #nav-dropdown {
				opacity: 1;
				visibility: visible;
				transform: translateY(0);
			}
		}
	}
}

// nav dropdown left side menu content
#nav-products-title {
	> li {
		display: block;
		padding: 10px 0;
		font-size: 16px;
		line-height: 26px;
		color: $text-grey-color;
		border-bottom: 1px solid $grey-light-border;
		transition: color .15s linear;
		&:last-of-type {
			border-bottom: none;
		}
		&.active {
			color: $text-black-color;
		}
		> a {
			display: block;
		}
	}
}

// nav dropdown right side content
#nav-dropdown-right {
	position: relative;
}
.nav-products-content {
	position: absolute;
	padding-top: rhythm(.5);
	opacity: 0;
	// transition: opacity .15s linear;
	&.active {
		opacity: 1;
	}
	> h3 {
		margin-bottom: rhythm(.75);
		font-weight: 400;
	}
	> p:last-of-type {
		margin-bottom: 0
	}
}

// nav shared small size styles
@mixin nav-small {
	padding: 20px 0;
	font-size: 16px;
	line-height: 48px;
	#nav-links > li {
		margin-right: 32px;
	}
	#nav-dropdown-trigger:after {
		margin-top: 20px;
	}
	#nav-login-btn {
		margin: 10px 24px;
		line-height: 28px;
	}
	#nav-book-btn {
		padding: 0 24px;
	}
}

// not mobile
@include slicer(-3) {
	// nav desktop styles
	#nav-mobile-logo, #nav-mobile-menu-btn, #nav-mobile-trial-btn {
		display: none;
	}
	#nav-mobile-dropdown {
		display: block !important;
	}
	#nav-mobile-content {
		@include clearfix;
	}
	// nav affix styles
	#nav.affix, #nav.hold {
		position: fixed;
		top: -88px;
		left: 0;
		right: 0;
		z-index: 1000;
		@include nav-small;
		transform: translate3D(0, 88px, 0);
		transition: transform .3s ease-in-out .1s;
		#nav-logo {
			display: none;
		}
		#nav-login-btn {
			transform: translateX(-175px);
			transition: transform .3s ease-in-out .55s;
		}
		#nav-book-btn {
			transform: translateX(-175px);
			transition: background .2s linear, transform .3s ease-in-out .55s;
		}
		#nav-trial-btn {
			z-index: 2;
			opacity: 1;
			transition: background .2s linear, opacity .25s ease-in-out .95s;
		}
		#nav-affix-logo > a {
			opacity: 1;
			transition: opacity .25s ease-in-out .95s;
		}
	}
	#nav.hold {
		transform: translate3D(0, 0, 0) !important;
	}
	#nav-affix-logo {
		position: relative;
		width: 0;
		height: 100%;
		margin: 0;
		> a {
			display: block;
			position: absolute;
			top: -4px;
			left: -88px; // (56+32)
			@include SVGsprite('Logo--icon-nav');
			opacity: 0;
		}
	}
}


@include slicer(2) {
	#nav {
		@include nav-small;
		&-outer {
			height: 88px;
		}
		&-dropdown {
			padding: rhythm(4) 0 rhythm(6);
		}
	}
}

@include slicer(3) {
	// hide elements
	#nav-dropdown-right, #nav-logo, #nav-affix-logo, #nav-trial-btn {
		display: none;
	}
	#landing #nav-outer {
		height: 0;
	}

	// nav mobile styles
	#nav {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 9999;
		padding: 0;
		border-bottom: none;
	}
	#nav-container {
		height: 88px;
	}

	#nav-mobile-trial-btn {
		display: block;
		position: absolute;
		top: 20px;
		left: 0;
		right: 0;
		margin: auto;
		width: 148px;
	}
	#nav-mobile-logo {
		float: left;
		margin-top: 16px;
		@include SVGsprite('Logo--icon-nav');
	}
	#nav-mobile-menu-btn {
		float: right;
		position: relative;
		width: 100px;
		height: 48px;
		margin-top: 20px;
		&:before {
			content: "";
			position: absolute;
			top: 17px;
			left: 17px;
			@include SVGsprite('Icon--hamburger');
		}
		> span {
			display: none;
			float: right;
			line-height: 48px;
			padding-right: 16px;
			text-align: right;
			&.active {
				display: block;
			}
		}
		&.active {
			&:before {
				@include SVGsprite('Icon--close');
			}
		}
	}

	// nav mobile dropdown styles
	#nav-mobile-dropdown {
		position: fixed;
		top: 88px;
		left: 0;
		right: 0;
		bottom: 0;
		overflow: auto;
		-webkit-overflow-scrolling: touch;
		background: $grey-3;
		opacity: 0;
		visibility: hidden;
		transform: translateY(-100%);
		transition: all .5s ease-in-out;
		&.active {
			opacity: 1;
			visibility: visible;
			transform: translateY(0);
		}
	}
	#nav-mobile-content {
		max-width: 600px;
		margin: auto;
		padding: rhythm(12) 20px;
	}

	#nav-links {
		float: none;
		> li {
			float: none;
			margin-bottom: rhythm(2);
			width: 100%;
			font-size: 22px;
			color: $text-black-color;
		}
	}
	#nav-buttons {
		float: none;
		> a:not(#nav-trial-btn) {
			display: block;
			float: none;
			margin: 0;
			padding: 0;
			font-size: 22px;
			text-align: left;
			color: $text-black-color;
			background: none;
			border: none;
			transition: none;
			&.active > a, &:hover > a {
				color: $text-dark-color;
			}
		}
	}
	#nav-login-btn, #nav-book-btn {
		line-height: 48px !important;
		margin-bottom: rhythm(2) !important;
	}
	// nav product sub menu mobile styles
	#nav-dropdown {
		position: static;
		padding: 0;
		border-top: none;
		border-bottom: none;
		background: transparent;
		opacity: 1;
		visibility: inherit;
		transform: translateY(0);
		&-trigger {
			color: $text-grey-color;
			&:after {
				display: none;
			}
		}
		&-left {
			padding: 0;
		}
	}
	#nav-products-title {
		> li {
			line-height: 20px;
			color: $text-black-color;
			border: none;
		}
	}
}

@include slicer(4) {
	#nav-mobile-trial-btn {
		display: none;
	}
	#nav-mobile-content {
		padding-top: rhythm(4);
	}
	#nav-trial-btn {
		position: static;
		display: block;
		float: none;
		opacity: 1;
		width: 100%;
		max-width: 280px;
		line-height: 56px;
	}
}
