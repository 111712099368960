.product-hero {
	padding-top: rhythm(15);
	padding-bottom: rhythm(15);
	background: $grey-3;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	&-title {
		margin-bottom: rhythm(2.5);
	}
	&-intro {
		margin-bottom: rhythm(5);
		@extend .title-dark-intro;

	}
	&-btn {
		display: inline-block;
		height: 64px;
		padding: 0 40px;
		font-size: 18px;
		line-height: 64px;
	}
	&-list {
		margin-bottom: rhythm(5);
		> li {
			position: relative;
			margin-bottom: rhythm(2);
			font-size: 22px;
			line-height: 32px;
			padding-left: 40px;
			margin-left: -40px;
			color: $text-dark-color;
			&:before {
				content: "";
				position: absolute;
				top: 2px;
				left: 0;
				@include SVGsprite('Icon--tick');
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	@include slicer(1) {
		padding-top: rhythm(12.5);
		padding-bottom: rhythm(12.5);
	}
}
