#icon {
	// featers icons
	&-alert--white:before {
		@include SVGsprite('Feature--alert-white');
	}
	&-chat--grey:before {
		@include SVGsprite('Feature--chat-grey');
	}
	&-chat--white:before {
		@include SVGsprite('Feature--chat-white');
	}
	&-coins--white:before {
		@include SVGsprite('Feature--coins-white');
	}
	&-computer--grey:before {
		@include SVGsprite('Feature--computer-grey');
	}
	&-integration--white:before {
		@include SVGsprite('Feature--integration-white');
	}
	&-integration--grey:before {
		@include SVGsprite('Feature--integration-grey');
	}
	&-milage--white:before {
		@include SVGsprite('Feature--milage-white');
	}
	&-mobile--grey:before {
		@include SVGsprite('Feature--mobile-grey');
	}
	&-mobile--white:before {
		@include SVGsprite('Feature--mobile-white');
	}
	&-people--grey:before {
		@include SVGsprite('Feature--people-grey');
	}
	&-productivity--grey:before {
		@include SVGsprite('Feature--productivity-grey');
	}
	&-productivity--white:before {
		@include SVGsprite('Feature--productivity-white');
	}
	&-reporting--grey:before {
		@include SVGsprite('Feature--reporting-grey');
	}
	&-reporting--white:before {
		@include SVGsprite('Feature--reporting-white');
	}
	&-scanning--white:before {
		@include SVGsprite('Feature--scanning-white');
	}
	&-team--grey:before {
		@include SVGsprite('Feature--team-grey');
	}
	&-time--grey:before {
		@include SVGsprite('Feature--time-grey');
	}
	&-time--white:before {
		@include SVGsprite('Feature--time-white');
	}
	&-time-passing--grey:before {
		@include SVGsprite('Feature--time-passing-grey');
	}
	&-time-passing--white:before {
		@include SVGsprite('Feature--time-passing-white');
	}
	&-receipt--grey:before {
		@include SVGsprite('Feature--receipt-grey');
	}
	&-export--grey:before {
		@include SVGsprite('Feature--export-grey');
	}
	&-hand--grey:before {
		@include SVGsprite('Feature--hand-grey');
	}
	&-hand--white:before {
		@include SVGsprite('Feature--hand-white');
	}
	&-screen--grey:before {
		@include SVGsprite('Feature--screen-grey');
	}
	&-graph--white:before {
		@include SVGsprite('Feature--graph-white');
	}
	&-graph-stats--grey:before {
		@include SVGsprite('Feature--graph-stats-grey');
	}
	&-graph-stats--white:before {
		@include SVGsprite('Feature--graph-stats-white');
	}
	&-application--grey:before {
		@include SVGsprite('Feature--application-grey');
	}
	&-application--white:before {
		@include SVGsprite('Feature--application-white');
	}
	&-lightbulb--grey:before {
		@include SVGsprite('Feature--lightbulb-grey');
	}
	&-sync--grey:before {
		@include SVGsprite('Feature--sync-grey');
	}
	&-process--grey:before {
		@include SVGsprite('Feature--process-grey');
	}
	&-commission--white:before {
		@include SVGsprite('Feature--commission-white');
	}

	// about value icons
	&-success--grey:before {
		@include SVGsprite('Feature--success-grey');
	}
	&-trust--grey:before {
		@include SVGsprite('Feature--trust-grey');
	}
	&-sandglass--grey:before {
		@include SVGsprite('Feature--sandglass-grey');
	}
	&-phones--grey:before {
		@include SVGsprite('Feature--phones-grey');
	}
	&-heart--grey:before {
		@include SVGsprite('Feature--heart-grey');
	}

	// partner page icons
	&-branding--grey:before {
		@include SVGsprite('Feature--branding-grey');
	}
	&-payroll--white:before {
		@include SVGsprite('Feature--payroll-white');
	}
	&-creditcard--grey:before {
		@include SVGsprite('Feature--creditcard-grey');
	}


	// integration logos
	&-integration-sap {
		@include SVGsprite('Integration--SAP', false);
	}
	&-integration-xero {
		@include SVGsprite('Integration--Xero', false);
	}
	&-integration-dropbox {
		@include SVGsprite('Integration--Dropbox', false);
	}


	// get app icons
	&-app-appstore:before {
		@include SVGsprite('Icon--appstore');
	}
	&-app-google-play:before {
		@include SVGsprite('Icon--google-play');
	}
	&-app-windows:before {
		@include SVGsprite('Icon--windows');
	}

	// tab icons & filter icons
	&-tab-single:before, &-filter-single:before {
		@include SVGsprite('Tab--single');
	}
	&-tab-team:before, &-filter-teams:before {
		@include SVGsprite('Tab--team');
	}
	&-tab-corporate:before, &-filter-corporations:before {
		@include SVGsprite('Tab--corporate');
	}
	&-tab-accountants:before {
		@include SVGsprite('Tab--accountants');
	}
	&-filter-all:before {
		@include SVGsprite('Tab--chat');
	}
	&-filter-financial:before {
		@include SVGsprite('Tab--financial');
	}
	&-filter-cloud:before {
		@include SVGsprite('Tab--cloud');
	}
	&-filter-software:before {
		@include SVGsprite('Tab--software');
	}
	&-filter-accounting:before {
		@include SVGsprite('Tab--accounting');
	}
	&-filter-integrations:before {
		@include SVGsprite('Tab--integrations');
	}
}
