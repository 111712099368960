%svg-common {
	background: url("../images/svg-sprite.svg") no-repeat;
}

.svg-Feature--alert-white {
	@extend %svg-common;
	background-position: 100% 64.83300589390963%;
}

.svg-Feature--alert-white-dims {
	width: 110px;
	height: 110px;
}

.svg-Feature--application-grey {
	@extend %svg-common;
	background-position: 100% 43.222003929273086%;
}

.svg-Feature--application-grey-dims {
	width: 110px;
	height: 110px;
}

.svg-Feature--application-white {
	@extend %svg-common;
	background-position: 100% 75.6385068762279%;
}

.svg-Feature--application-white-dims {
	width: 110px;
	height: 110px;
}

.svg-Feature--branding-grey {
	@extend %svg-common;
	background-position: 64.24778761061947% 15.520628683693516%;
}

.svg-Feature--branding-grey-dims {
	width: 110px;
	height: 110px;
}

.svg-Feature--chat-grey {
	@extend %svg-common;
	background-position: 64.24778761061947% 26.326129666011788%;
}

.svg-Feature--chat-grey-dims {
	width: 110px;
	height: 110px;
}

.svg-Feature--chat-white {
	@extend %svg-common;
	background-position: 64.24778761061947% 37.13163064833006%;
}

.svg-Feature--chat-white-dims {
	width: 110px;
	height: 110px;
}

.svg-Feature--coins-white {
	@extend %svg-common;
	background-position: 64.24778761061947% 47.93713163064833%;
}

.svg-Feature--coins-white-dims {
	width: 110px;
	height: 110px;
}

.svg-Feature--commission-white {
	@extend %svg-common;
	background-position: 64.24778761061947% 58.7426326129666%;
}

.svg-Feature--commission-white-dims {
	width: 110px;
	height: 110px;
}

.svg-Feature--computer-grey {
	@extend %svg-common;
	background-position: 64.24778761061947% 69.54813359528487%;
}

.svg-Feature--computer-grey-dims {
	width: 110px;
	height: 110px;
}

.svg-Feature--creditcard-grey {
	@extend %svg-common;
	background-position: 64.24778761061947% 80.35363457760315%;
}

.svg-Feature--creditcard-grey-dims {
	width: 110px;
	height: 110px;
}

.svg-Feature--export-grey {
	@extend %svg-common;
	background-position: 80.53097345132744% 0;
}

.svg-Feature--export-grey-dims {
	width: 110px;
	height: 110px;
}

.svg-Feature--graph-stats-grey {
	@extend %svg-common;
	background-position: 80.53097345132744% 10.805500982318271%;
}

.svg-Feature--graph-stats-grey-dims {
	width: 110px;
	height: 110px;
}

.svg-Feature--graph-stats-white {
	@extend %svg-common;
	background-position: 80.53097345132744% 21.611001964636543%;
}

.svg-Feature--graph-stats-white-dims {
	width: 110px;
	height: 110px;
}

.svg-Feature--graph-white {
	@extend %svg-common;
	background-position: 80.53097345132744% 32.416502946954814%;
}

.svg-Feature--graph-white-dims {
	width: 110px;
	height: 110px;
}

.svg-Feature--hand-grey {
	@extend %svg-common;
	background-position: 80.53097345132744% 43.222003929273086%;
}

.svg-Feature--hand-grey-dims {
	width: 110px;
	height: 110px;
}

.svg-Feature--hand-white {
	@extend %svg-common;
	background-position: 80.53097345132744% 54.02750491159136%;
}

.svg-Feature--hand-white-dims {
	width: 110px;
	height: 110px;
}

.svg-Feature--heart-grey {
	@extend %svg-common;
	background-position: 80.53097345132744% 64.83300589390963%;
}

.svg-Feature--heart-grey-dims {
	width: 110px;
	height: 110px;
}

.svg-Feature--integration-grey {
	@extend %svg-common;
	background-position: 80.53097345132744% 75.6385068762279%;
}

.svg-Feature--integration-grey-dims {
	width: 110px;
	height: 110px;
}

.svg-Feature--integration-white {
	@extend %svg-common;
	background-position: 80.53097345132744% 86.44400785854617%;
}

.svg-Feature--integration-white-dims {
	width: 110px;
	height: 110px;
}

.svg-Feature--integrations-grey {
	@extend %svg-common;
	background-position: 90.26548672566372% 0;
}

.svg-Feature--integrations-grey-dims {
	width: 110px;
	height: 110px;
}

.svg-Feature--lightbulb-grey {
	@extend %svg-common;
	background-position: 90.26548672566372% 10.805500982318271%;
}

.svg-Feature--lightbulb-grey-dims {
	width: 110px;
	height: 110px;
}

.svg-Feature--milage-white {
	@extend %svg-common;
	background-position: 90.26548672566372% 21.611001964636543%;
}

.svg-Feature--milage-white-dims {
	width: 110px;
	height: 110px;
}

.svg-Feature--mobile-grey {
	@extend %svg-common;
	background-position: 90.26548672566372% 32.416502946954814%;
}

.svg-Feature--mobile-grey-dims {
	width: 110px;
	height: 110px;
}

.svg-Feature--mobile-white {
	@extend %svg-common;
	background-position: 90.26548672566372% 43.222003929273086%;
}

.svg-Feature--mobile-white-dims {
	width: 110px;
	height: 110px;
}

.svg-Feature--payroll-white {
	@extend %svg-common;
	background-position: 90.26548672566372% 54.02750491159136%;
}

.svg-Feature--payroll-white-dims {
	width: 110px;
	height: 110px;
}

.svg-Feature--people-grey {
	@extend %svg-common;
	background-position: 90.26548672566372% 64.83300589390963%;
}

.svg-Feature--people-grey-dims {
	width: 110px;
	height: 110px;
}

.svg-Feature--phones-grey {
	@extend %svg-common;
	background-position: 90.26548672566372% 75.6385068762279%;
}

.svg-Feature--phones-grey-dims {
	width: 110px;
	height: 110px;
}

.svg-Feature--process-grey {
	@extend %svg-common;
	background-position: 90.26548672566372% 86.44400785854617%;
}

.svg-Feature--process-grey-dims {
	width: 110px;
	height: 110px;
}

.svg-Feature--productivity-grey {
	@extend %svg-common;
	background-position: 0 100%;
}

.svg-Feature--productivity-grey-dims {
	width: 110px;
	height: 110px;
}

.svg-Feature--productivity-white {
	@extend %svg-common;
	background-position: 9.734513274336283% 100%;
}

.svg-Feature--productivity-white-dims {
	width: 110px;
	height: 110px;
}

.svg-Feature--receipt-grey {
	@extend %svg-common;
	background-position: 19.469026548672566% 100%;
}

.svg-Feature--receipt-grey-dims {
	width: 110px;
	height: 110px;
}

.svg-Feature--reporting-grey {
	@extend %svg-common;
	background-position: 29.20353982300885% 100%;
}

.svg-Feature--reporting-grey-dims {
	width: 110px;
	height: 110px;
}

.svg-Feature--reporting-white {
	@extend %svg-common;
	background-position: 38.93805309734513% 100%;
}

.svg-Feature--reporting-white-dims {
	width: 110px;
	height: 110px;
}

.svg-Feature--sandglass-grey {
	@extend %svg-common;
	background-position: 48.67256637168141% 100%;
}

.svg-Feature--sandglass-grey-dims {
	width: 110px;
	height: 110px;
}

.svg-Feature--scanning-white {
	@extend %svg-common;
	background-position: 58.4070796460177% 100%;
}

.svg-Feature--scanning-white-dims {
	width: 110px;
	height: 110px;
}

.svg-Feature--screen-grey {
	@extend %svg-common;
	background-position: 68.14159292035399% 100%;
}

.svg-Feature--screen-grey-dims {
	width: 110px;
	height: 110px;
}

.svg-Feature--success-grey {
	@extend %svg-common;
	background-position: 77.87610619469027% 100%;
}

.svg-Feature--success-grey-dims {
	width: 110px;
	height: 110px;
}

.svg-Feature--sync-grey {
	@extend %svg-common;
	background-position: 87.61061946902655% 100%;
}

.svg-Feature--sync-grey-dims {
	width: 110px;
	height: 110px;
}

.svg-Feature--team-grey {
	@extend %svg-common;
	background-position: 100% 0;
}

.svg-Feature--team-grey-dims {
	width: 110px;
	height: 110px;
}

.svg-Feature--time-grey {
	@extend %svg-common;
	background-position: 100% 10.805500982318271%;
}

.svg-Feature--time-grey-dims {
	width: 110px;
	height: 110px;
}

.svg-Feature--time-passing-grey {
	@extend %svg-common;
	background-position: 100% 21.611001964636543%;
}

.svg-Feature--time-passing-grey-dims {
	width: 110px;
	height: 110px;
}

.svg-Feature--time-passing-white {
	@extend %svg-common;
	background-position: 100% 32.416502946954814%;
}

.svg-Feature--time-passing-white-dims {
	width: 110px;
	height: 110px;
}

.svg-Feature--time-white {
	@extend %svg-common;
	background-position: 64.24778761061947% 4.715127701375246%;
}

.svg-Feature--time-white-dims {
	width: 110px;
	height: 110px;
}

.svg-Feature--trust-grey {
	@extend %svg-common;
	background-position: 100% 54.02750491159136%;
}

.svg-Feature--trust-grey-dims {
	width: 110px;
	height: 110px;
}

.svg-Icon--appstore {
	@extend %svg-common;
	background-position: 93.54304635761589% 98.54014598540147%;
}

.svg-Icon--appstore-dims {
	width: 32px;
	height: 32px;
}

.svg-Icon--arrow-down {
	@extend %svg-common;
	background-position: 89.43471074380166% 89.35018050541517%;
}

.svg-Icon--arrow-down-dims {
	width: 30px;
	height: 20px;
}

.svg-Icon--arrow-left {
	@extend %svg-common;
	background-position: 99.91809991809991% 90.08189262966333%;
}

.svg-Icon--arrow-left-dims {
	width: 19px;
	height: 29px;
}

.svg-Icon--arrow-right {
	@extend %svg-common;
	background-position: 99.91809991809991% 92.72065514103731%;
}

.svg-Icon--arrow-right-dims {
	width: 19px;
	height: 29px;
}

.svg-Icon--at {
	@extend %svg-common;
	background-position: 69.20529801324503% 17.335766423357665%;
}

.svg-Icon--at-dims {
	width: 32px;
	height: 32px;
}

.svg-Icon--close {
	@extend %svg-common;
	background-position: 99.8371335504886% 96.7741935483871%;
}

.svg-Icon--close-dims {
	width: 12px;
	height: 12px;
}

.svg-Icon--close-grey {
	@extend %svg-common;
	background-position: 81.87808896210873% 89.83666061705989%;
}

.svg-Icon--close-grey-dims {
	width: 26px;
	height: 26px;
}

.svg-Icon--cross {
	@extend %svg-common;
	background-position: 71.00494233937397% 34.30127041742287%;
}

.svg-Icon--cross-dims {
	width: 26px;
	height: 26px;
}

.svg-Icon--cross-white {
	@extend %svg-common;
	background-position: 79.73640856672158% 89.83666061705989%;
}

.svg-Icon--cross-white-dims {
	width: 26px;
	height: 26px;
}

.svg-Icon--error {
	@extend %svg-common;
	background-position: 68.63711001642037% 12.296564195298373%;
}

.svg-Icon--error-dims {
	width: 22px;
	height: 22px;
}

.svg-Icon--form-success {
	@extend %svg-common;
	background-position: 100% 86.44400785854617%;
}

.svg-Icon--form-success-dims {
	width: 110px;
	height: 110px;
}

.svg-Icon--google-play {
	@extend %svg-common;
	background-position: 69.20529801324503% 9.489051094890511%;
}

.svg-Icon--google-play-dims {
	width: 32px;
	height: 32px;
}

.svg-Icon--google-plus {
	@extend %svg-common;
	background-position: 71.8543046357616% 9.489051094890511%;
}

.svg-Icon--google-plus-dims {
	width: 32px;
	height: 32px;
}

.svg-Icon--google-plus-white {
	@extend %svg-common;
	background-position: 69.20529801324503% 14.416058394160585%;
}

.svg-Icon--google-plus-white-dims {
	width: 32px;
	height: 32px;
}

.svg-Icon--hamburger {
	@extend %svg-common;
	background-position: 99.51060358890702% 95.6989247311828%;
}

.svg-Icon--hamburger-dims {
	width: 14px;
	height: 12px;
}

.svg-Icon--info {
	@extend %svg-common;
	background-position: 100% 94.58483754512635%;
}

.svg-Icon--info-dims {
	width: 20px;
	height: 20px;
}

.svg-Icon--info-white {
	@extend %svg-common;
	background-position: 68.86326194398681% 36.66061705989111%;
}

.svg-Icon--info-white-dims {
	width: 26px;
	height: 26px;
}

.svg-Icon--linkedin {
	@extend %svg-common;
	background-position: 69.20529801324503% 20.255474452554743%;
}

.svg-Icon--linkedin-dims {
	width: 32px;
	height: 32px;
}

.svg-Icon--linkedin-white {
	@extend %svg-common;
	background-position: 71.8543046357616% 20.255474452554743%;
}

.svg-Icon--linkedin-white-dims {
	width: 32px;
	height: 32px;
}

.svg-Icon--plus {
	@extend %svg-common;
	background-position: 99.51298701298701% 97.5%;
}

.svg-Icon--plus-dims {
	width: 8px;
	height: 8px;
}

.svg-Icon--plus-circle {
	@extend %svg-common;
	background-position: 71.8543046357616% 17.335766423357665%;
}

.svg-Icon--plus-circle-dims {
	width: 32px;
	height: 32px;
}

.svg-Icon--tick {
	@extend %svg-common;
	background-position: 68.86326194398681% 34.30127041742287%;
}

.svg-Icon--tick-dims {
	width: 26px;
	height: 26px;
}

.svg-Icon--twitter {
	@extend %svg-common;
	background-position: 71.8543046357616% 14.416058394160585%;
}

.svg-Icon--twitter-dims {
	width: 32px;
	height: 32px;
}

.svg-Icon--twitter-white {
	@extend %svg-common;
	background-position: 98.84105960264901% 98.54014598540147%;
}

.svg-Icon--twitter-white-dims {
	width: 32px;
	height: 32px;
}

.svg-Icon--windows {
	@extend %svg-common;
	background-position: 96.19205298013244% 98.54014598540147%;
}

.svg-Icon--windows-dims {
	width: 32px;
	height: 32px;
}

.svg-Logo--icon {
	@extend %svg-common;
	background-position: 98.26086956521739% 95.3757225433526%;
}

.svg-Logo--icon-dims {
	width: 90px;
	height: 90px;
}

.svg-Logo--icon-nav {
	@extend %svg-common;
	background-position: 70.60810810810811% 4.477611940298507%;
}

.svg-Logo--icon-nav-dims {
	width: 56px;
	height: 56px;
}

.svg-Logo--icon-white {
	@extend %svg-common;
	background-position: 63.020833333333336% 89.23076923076923%;
}

.svg-Logo--icon-white-dims {
	width: 88px;
	height: 88px;
}

.svg-Logo--shape {
	@extend %svg-common;
	background-position: 0 0;
}

.svg-Logo--shape-dims {
	width: 726px;
	height: 1018px;
}

.svg-Logo--text {
	@extend %svg-common;
	background-position: 68.75% 0;
}

.svg-Logo--text-dims {
	width: 184px;
	height: 48px;
}

.svg-Tab--accountants {
	@extend %svg-common;
	background-position: 71.38441879637263% 29.70027247956403%;
}

.svg-Tab--accountants-dims {
	width: 27px;
	height: 27px;
}

.svg-Tab--accounting {
	@extend %svg-common;
	background-position: 86.94458229942101% 89.67391304347827%;
}

.svg-Tab--accounting-dims {
	width: 31px;
	height: 24px;
}

.svg-Tab--chat {
	@extend %svg-common;
	background-position: 69.14805624483044% 24.408014571949%;
}

.svg-Tab--chat-dims {
	width: 31px;
	height: 30px;
}

.svg-Tab--cloud {
	@extend %svg-common;
	background-position: 75.26881720430107% 89.83666061705989%;
}

.svg-Tab--cloud-dims {
	width: 31px;
	height: 26px;
}

.svg-Tab--corporate {
	@extend %svg-common;
	background-position: 84.37841236226465% 89.9182561307902%;
}

.svg-Tab--corporate-dims {
	width: 31.16px;
	height: 27px;
}

.svg-Tab--financial {
	@extend %svg-common;
	background-position: 71.712158808933% 24.385805277525023%;
}

.svg-Tab--financial-dims {
	width: 31px;
	height: 29px;
}

.svg-Tab--integrations {
	@extend %svg-common;
	background-position: 77.5762572135202% 89.83666061705989%;
}

.svg-Tab--integrations-dims {
	width: 27px;
	height: 26px;
}

.svg-Tab--single {
	@extend %svg-common;
	background-position: 71.56391955422124% 27.06630336058129%;
}

.svg-Tab--single-dims {
	width: 29.893px;
	height: 27px;
}

.svg-Tab--software {
	@extend %svg-common;
	background-position: 69.0909090909091% 27.115559599636033%;
}

.svg-Tab--software-dims {
	width: 30px;
	height: 29px;
}

.svg-Tab--team {
	@extend %svg-common;
	background-position: 69.08479993917894% 29.70027247956403%;
}

.svg-Tab--team-dims {
	width: 29.893px;
	height: 27px;
}

