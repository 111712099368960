$tab-timing: .2s ease-in-out;

// tab controller
.nav-tabs:not(#product-tabs-controller) {
	padding-left: 20px;
	padding-right: 14px;
	user-select: none;
	@include clearfix;
	> li {
		position: relative;
		z-index: 0;
		float: left;
		margin: 0 -1px;
		color: $text-grey-color;
		background: $grey-light-background;
		border: 2px solid $landing-tab-border;
		transition: all $tab-timing;
		&:before {
			content: "";
			position: absolute;
			top: 11px;
			right: 11px;
			z-index: 1;
			width: 22px;
			height: 22px;
			background: $grey-4;
			border-radius: 50%;
			// transition: background $tab-timing;
		}
		&:after {
			content: "";
			position: absolute;
			top: 18px;
			right: 18px;
			z-index: 2;
			width: 8px;
			height: 8px;
			background: $green;
			border-radius: 50%;
			transform: scale(0, 0);
			transition: transform $tab-timing;
		}
		&:first-child {
			border-radius: 3px 0 0 3px;
		}
		&:last-child {
			border-radius: 0 3px 3px 0;
		}
		&:hover {
			color: $text-dark-color;
			background: $white;
		}
		&.active {
			z-index: 1;
			color: $text-dark-color;
			background: $white;
			border-color: $landing-tab-active-border;
			&:before {
				background: $green-1;
			}
			&:after {
				transform: scale(1, 1);
			}
		}
		> a, > span {
			display: block;
			padding-left: 20px;
			height: 44px;
			line-height: 44px;
		}
	}
}

// tab pane
.tab-pane:not(.customers-tab) {
	display: none;
	@include clearfix;
	&.active {
		display: block;
	}
}

@include slicer(4) {
	.nav-tabs:not(#product-tabs-controller):not(#price-plan-controller) {
		> li {
			&:before, &:after {
				display: none;
			}
			&:first-child, &:last-child {
				border-radius: 0;
			}
			&.active {
				border-color: $landing-tab-border;
				border-top-color: $green;
				border-bottom-color: transparent;
				> a, > span {
					filter: none !important;
				}
			}
			> a, > span {
				height: auto;
				min-height: 80px;
				padding: 13px 0 7px;
				font-size: 10px;
				line-height: 20px;
				text-align: center;
				color: $text-black-color;
				@include dimImage(grayscale(100%));
				&:before {
					content: "";
					display: block;
					margin: 0 auto 8px;
				}
			}
		}
	}
	#price-plan-controller {
		padding-left: 0 !important;
		padding-right: 0 !important;
		> li > .text-highlight {
			display: none !important;
		}
	}
}
