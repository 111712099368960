%dim-background {
	transition: background .2s linear;
}



// buttons
.btn{
	display: inline-block;
	height: 56px;
	font-size: 18px;
	line-height: 54px;
	padding: 0 40px;
	text-align: center;
	&-red {
		color: $white;
		background: $red;
		border-radius: 3px;
		text-align: center;
		@extend %dim-background;
		&:hover {
			background: darken($red, 10%);
		}
	}

	&-green {
		color: $white;
		background: $green;
		border-radius: 3px;
		text-align: center;
		@extend %dim-background;
		&:hover {
			background: darken($green, 5%);
		}
	}

	&-grey {
		color: $text-dark-color;
		background: $grey-3;
		border-radius: 3px;
		text-align: center;
		@extend %dim-background;
		&:hover {
			background: darken($grey-3, 5%);
		}
	}

	&-demo {
		width: 100%;
		border: none;
	}
}

// button groups
.btn-group {
  position: relative;
  display: inline-block;
  vertical-align: middle; // match .btn alignment given font-size hack above
  > .btn {
    position: relative;
    float: left;
    margin: 0 15px 0 0;
    &:last-child {
   		margin-right: 0;
   	}
  }
}

.panel{
	margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.05);
    box-shadow: 0 1px 1px rgba(0,0,0,.05);
    padding: 10px;
}

// breakpoist 680px
@include slicer(4) {
	.btn-group {
		text-align: center;
		margin: 0 auto;
		display: block;
	  > .btn {
	    float: none;
	    margin: 0 auto rhythm(2);
	    &:last-child{
	    	margin-bottom:0;
	    }
	  }
	}
}