#quote {
	&-main {
		padding-top: rhythm(7.5);
		padding-bottom: rhythm(15);
		background-image: linear-gradient(0deg, rgba(251,252,252,0.00) 0%, #F5F7F7 100%);
		&:after {
			content: "";
			position: absolute;
			top: 5.5%;
			left: -11%;
			z-index: 1;
			@include SVGsprite('Logo--shape');
		}
		> .container-11 {
			position: relative;
			z-index: 2;
		}
		p > a {
			@extend %transform-links;
		}
		.container-title {
			text-align: left;
			padding: 0;
			margin-bottom: rhythm(4);
		}
	}
	&-title-group {
		position: relative;
		z-index: 2;
	}
	&-title {
		margin-bottom: rhythm(4.25);
		text-align: center;
	}
	&-message-title {
		margin-bottom: rhythm(1.5);
	}
	&-intro {
		@extend .title-dark-intro;
	}
	&-trust {

		> #trust-list {
			margin-left: (-15px/2);
			margin-right: (-15px/2);
			@include clearfix;
			> .trust-company {
				float: left;
				width: percentage(1/3);
				margin: 0;
				padding: 0 (15px/2);
				margin-bottom: rhythm(2);
			}
			@at-root {
				.trust-company-logo {
					display: block;
					height: 88px;
					background: $white;
					border-radius: 3px;
					> img {
						display: block;
						height: 100%;
						margin: auto;
						@include dimImage();
					}
				}
			}
		}

		.testimonial-wrap {
			margin-top: rhythm(6);
		}

		.carousel-inner .item {
			min-height: 240px;
		}
	}
}
