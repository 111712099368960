#addon {
	&-hero {
		background: $grey-1;
		overflow: hidden;
		&-left {
			padding-top: rhythm(10);
			padding-bottom: rhythm(10);
			> p {
				color: $text-grey-color;
			}
			> p > strong {
				font-weight: normal;
				color: $text-black-color;
			}
		}
		&-integration {
			margin-bottom: rhythm(3);
			@include clearfix;
			&-logo {
				display: block;
				&:before {
					content: "";
					float: left;
					@include SVGsprite('Logo--icon-white');
				}
				&:after {
					content: "";
					float: left;
					margin: 24px 24px 0;
					@include SVGsprite('Icon--plus-circle');
				}
			}
			> img {
				float: left;
				height: 88px;
				background: $white;
				border-radius: 3px;
			}
		}
		&-title {
			margin-bottom: rhythm(2.25);
		}
		&-intro {
			@extend .title-dark-intro;
			margin-bottom: rhythm(3.5);
		}
		&-right {
			position: relative;
			> .product-image {
				position: absolute;
				top: rhythm(10);
				left: 45px;
				max-width: 675px;
			}
		}
		&-bar {
			padding: 20px 0;
			background: #ebfcf7;
			> .container-11--gutter {
				> p {
					float: left;
					margin: 0 20px 0 0;
					padding: 10px 0;
					font-size: 18px;
					line-height: 28px;
					color: $green;
				}
				> .btn-green {
					float: left;
					padding: 0 24px;
					height: 48px;
					line-height: 48px;
				}
			}
		}
	}

	&-steps {
		padding-top: rhythm(15);
		padding-bottom: rhythm(10);
	}
	@at-root {
		.addon-step {
			position: relative;
			margin-bottom: rhythm(5);
			&:before {
				content: "";
				display: block;
				margin-bottom: rhythm(4.5);
			}
			&:after {
				content: attr(data-step);
				position: absolute;
				top: rhythm(11);
				color: $white;
				background: $green;
				width: 45px;
				height: 45px;
				font-size: 22px;
				text-align: center;
				line-height: 45px;
				border-radius: 50%;
			}
			> h3 {
				margin-bottom: rhythm(1.5);
				font-weight: normal;
			}
			> p {
				margin-bottom: 0;
				color: $text-grey-color;
				line-height: 28px;
			}
		}
	}

	&-testimonials {
		padding-top: rhythm(10);
		padding-bottom: rhythm(10);
		background: $grey-dark-background;
		@at-root {
			.addon-testimonial {
				&-avatar {
					float: left;
					width: 160px;
					height: 160px;
					margin-right: 65px;
					background: $grey-dark-background;
					border-radius: 50%;
				}
				&-content {
					float: left;
					width: calc(100% - 340px);
					padding-top: rhythm(2);
					> p {
						margin-bottom: rhythm(3);
						font-size: 22px;
						line-height: 32px;
					}
				}
				&-author {
					font-size: 16px;
					line-height: 22px;
					color: $text-light-color;

					> strong {
						font-weight: normal;
						color: $text-grey-color;
					}
				}
			}
		}
	}

	&-connecting {
		padding:rhythm(15) 0;
		> .container-9 {
			margin-bottom: rhythm(12.5);
			position: relative;
		}
		@at-root {
			.addon-connecting-left {
				//padding-bottom: rhythm(7);
				> h2 {
					margin-bottom: rhythm(2.5);
					+ h3 {
						margin-top: 0;
					}
				}
				> h3 {
					//margin-top: rhythm(9.5);
					margin-bottom: rhythm(2.5);
					font-weight: normal;
				}
				> ol {
					padding-left: 22px;
					margin-bottom: rhythm(5);
					color: $text-grey-color;
					line-height: 28px;
					> li {
						margin-bottom: rhythm(2.5);
						> strong {
							font-weight: normal;
							color: $text-black-color;
						}
					}
				}
				> .note {
					margin-bottom: rhythm(1);
					line-height: 24px;
					@include clearfix;
					> span {
						float: left;
						padding: 0 4px;
						margin-right: 10px;
					}
				}
				> .btn-green {
					display: inline-block;
					margin-top: rhythm(3);
					padding: 0 20px;
					line-height: 48px;
					cursor: pointer;
				}
			}
			.addon-connecting-right {
				position: relative;
				padding-top: rhythm(12.5);
				> .addon-video {
					position: absolute;
					top: 0;
					left: 45px;
				}
			}
		}
	}
}
