// enquiry/contact panel
.enquiry {
	padding: 0 20px;
	margin: 0 auto rhythm(15);
	max-width: 650px;
	text-align: center;
	&-title {
		margin-bottom: rhythm(4);
	}
	&-panel {
		padding: rhythm(4) 100px rhythm(6);
		background: $grey-dark-background;
		border-radius: 3px;
		> p {
			font-size: 22px;
			line-height: 38px;
			color: $text-black-color;
		}
	}
	&-dropdown {
		float: none;
		margin: auto;
		width: 270px;
		text-align: left;
		@include clearfix;
	}
	
	@include slicer(4) {
		margin-bottom: rhythm(7);
	}
}