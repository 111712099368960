// hero
#landing-hero {
	position: relative;
	padding-top: rhythm(6);
	padding-bottom: rhythm(25);
	@include heroBG('beta');
	&-logo {
		display: block;
		margin-bottom: rhythm(12.5);
		@include SVGsprite('Logo--text');
	}
	&-title {
		margin-bottom: rhythm(2);
		//max-width: 500px;
		font-size: 48px;
		line-height: 72px;
		font-weight: 500;
		color: $hero-title-color;
	}
	&-intro {
		margin-bottom: rhythm(5);
		@extend .title-dark-intro;
		max-width: 630px;
		font-size: 24px;
		line-height: 32px;
	}

	&-dropdowns {
		position: absolute;
		top: 0;
		right: 15px;
		z-index: 10;
	}
	&-grid {
		.trial-form{
			max-width: 630px;
		}
	}
}

// steps section
#landing-steps {
	padding-top: rhythm(10);
	> .container-title {
		margin-bottom: 0;
	}
	> .container-11 {
		position: relative;
	}
	// titles
	&-sup {
		display: block;
		margin-bottom: rhythm(5);
		font-size: 16px;
		line-height: 22px;
		color: $text-light-color;
	}
	&-title {
		margin-bottom: rhythm(2.5);
	}
	&-intro {
		max-width: 780px;
		margin: auto;
	}

	// left side images
	&-images {
		width: 100%;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 40%;
		background: url('../images/landing/steps.jpg') right;
		@include retina {
			//background: url('../images/landing/steps@2x.jpg');
		}
	}

	// right side content
	&-content {
		position: relative;
		padding: rhythm(10) 20px rhythm(15) (1.175/11 * $max-width);
		&:before {
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			left: 68px;
			z-index: 1;
			width: 2px;
			height: 100%;
			background: $grey-light-border;
		}
		// line fade
		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 68px;
			z-index: 2;
			width: 2px;
			height: 70px;
			background-image: linear-gradient(to bottom, $white 0%, $grey-light-border 100%);
		}
	}
	&-request {
		display: inline-block;
		//width: 205px;
		height: 56px;
		font-size: 18px;
		line-height: 54px;
		padding: 0 40px;
		text-align: center;
	}
}


.landing-step {
	position: relative;
	padding-bottom: rhythm(7);
	&:before {
		content: attr(data-step);
		position: absolute;
		top: 0;
		left: -113px;
		z-index: 3;
		width: 45px;
		height: 45px;
		font-size: 22px;
		line-height: 46px;
		text-align: center;
		color: $white;
		background: $green;
		border-radius: 50%;
	}
	> h3 {
		padding-top: rhythm(1);
		margin-bottom: rhythm(.5);
		font-weight: 400;
		color: $text-7;
	}
	> p {
		color: $text-grey-color;
		&:last-of-type {
			margin-bottom: 0;
		}
	}
}


// features section
#landing-features {
	padding: rhythm(14.5) 0 rhythm(10);
	background: $grey-dark-background;
	&-title {
		margin-bottom: 0;
		text-align: center;
	}
	&-arrow {
		display: block;
		margin: rhythm(3) auto 0;
		@include SVGsprite('Icon--arrow-down');
	}
	> .container-title {
		margin-bottom: 0;
	}
}
.landing-features-item {
	padding-top: rhythm(9.5);
	text-align: center;
	color: $text-gloomy-color;
	&:before {
		content: "";
		display: block;
		margin: 0 auto rhythm(2.5);
	}
	> h3 {
		margin-bottom: rhythm(1.5);
		font-weight: 400;
	}
	> p:last-of-type {
		margin-bottom: 0;
	}
}

// integration
#landing-integrations {
	padding-top: rhythm(4.5);
	margin: 0 auto rhythm(4);
	max-width: 810px;
	text-align:center;
	@include clearfix;
	&-more {
		display: block;
		max-width: 300px;
		margin: 0 auto;
		text-align: center;
		line-height: 24px;
	}
}
.landing-integration {
	display: block;
	background: $white;
	border-radius: 3px;
	> img {
		display: block;
		height: 88px;
		margin: auto;
		@include dimImage( grayscale(90%) opacity(45%) );
	}
}

#landing-features-more {
	display: inline-block;
	margin: rhythm(7) auto 0;
	//width: 198px;
	padding: 0 24px;
	height: 48px;
	line-height: 48px;
}


// benefits section
#landing-benefits {
	padding-top: rhythm(15);
	padding-bottom: rhythm(15);
	&-title-group {
		margin-bottom: rhythm(6.5);
		text-align: center;
	}
	&-title {
		margin-bottom: rhythm(1.5);
	}
}

#landing-tabs-controller {
	margin: 0 auto rhythm(10);
	max-width: 938px;
	> li {
		width: 25%;
	}
}

.landing-tab {
	@include clearfix;
	// left part
	&-benefits {
		margin-bottom: rhythm(5);
	}
	&-benefit {
		position: relative;
		padding-left: 135px;
		margin-bottom: rhythm(6);
		color: $text-grey-color;
		&:last-of-type {
			margin-bottom: 0;
		}
		&:before {
			content: "";
			position: absolute;
			top: 6px;
			left: 0;
		}
		> h4 {
			font-weight: 400;
			margin-bottom: rhythm(.5);
		}
	}
	&-more {
		display: inline-block;
		margin-left: 135px;
		// width: 150px;
		padding: 0 24px;
		height: 48px;
		line-height: 48px;
	}
}

// landing testimonial
.landing-testimonial {
	padding: rhythm(6) 50px;
	background-image: linear-gradient(0deg, rgba(251,252,252,0.00) 0%, #F5F7F7 100%);
	border-radius: 3px;

	.carousel-inner .item {
		min-height: 240px;
	}
}

// km
//Don't show nav on landing pages
.affix-top{
	visibility: hidden;
}
#nav-outer{
	height: 0;
}
#km .testimonial-content{
	margin-left: 15%;
	width: 70%;
	text-align: center;
}
.login {
	position: relative;
	float: left;
	height: 48px;
	font-size: 15px;
	line-height: 44px;
	text-align: center;
	color: $text-grey-color;
	border: 2px solid;
	border-radius: 3px;
	padding: 0px 20px;
	background: $grey-light-background;
	border-color: $landing-tab-border;
}


