//hero
#landing-hero.beta {
	@include heroBG('beta');
}

//form
form input{
	border: none;
}

//feedback
#beta-feedback{
	background: $grey-dark-background;
	padding: rhythm(14.5) 0 rhythm(0);
    margin-bottom: -16px;

	.enquiry{
		margin: 0 auto;
	}
}

//Hide trial
#footer {
	#footer-title-group,
	#footer-trial-form{
		display: none;
	}
}

.beta-block-white{
  	padding-top: 60px;
 	padding-bottom: 120px;
	background-color: #fff;
}

.beta-block-grey{
  	padding-top: 60px;
 	padding-bottom: 120px;
	background-color: #ededed;
}



.beta-block-white ul{
	list-style: initial;
	text-align: left;
	padding-bottom: 10px;
}

.beta-block-white ul li{
	line-height: 1.5rem;

	margin-left: 20px;

}
