.case-card {
	display: none;
	margin-bottom: rhythm(6);
	&-link, &-image {
		display: block;
		height: 200px;
		background-color: $grey-dark-background;
		border-radius: 3px 3px 0 0;
	}
	&-image {
		background-position: center center;
		background-size: cover;
		border-radius: 3px 3px 0 0;
	}
	&-link-image {
		display: block;
		height: 100%;
		background-position: center center;
		background-size: cover;
		border-radius: 3px 3px 0 0;
		opacity: 1;
		transition: opacity .35s ease-out;
		&:hover {
			opacity: .8;
		}
	}
	&-logo {
		height: 89px;
		background: $grey-light-background;
		border-left: 1px solid $grey-light-border;
		border-right: 1px solid $grey-light-border;
		border-bottom: 1px solid $grey-light-border;
		> img {
			display: block;
			margin: auto;
			height: 100%;
		}
	}
	&-content {
		padding: 20px 20px 0;
		color: $text-grey-color;
		border-left: 1px solid $grey-light-border;
		border-right: 1px solid $grey-light-border;
		> p {
			margin-bottom: 0;
		}
	}
	&-bottom {
		padding: 20px;
		border-left: 1px solid $grey-light-border;
		border-right: 1px solid $grey-light-border;
		border-bottom: 1px solid $grey-light-border;
		border-radius: 0 0 3px 3px;
		font-size: 14px;
		color: $text-light-color;
		> strong {
			display: block;
			margin-bottom: rhythm(1);
			font-size: 14px;
			font-weight: normal;
			color: $text-grey-color;
		}
		> span {
			display: block;
			font-size: 14px;
			color: $text-light-color;
		}
		> a {
			display: block;
			margin-top: 10px;
			margin-bottom: 10px;
			color: $green;
			font-size: 16px;
		}
	}
	&.case-study {
		> .case-card-link {
			position: relative;
			> span {
				position: absolute;
				bottom: 20px;
				left: 20px;
				z-index: 1;
				padding: 10px;
				font-size: 12px;
				font-weight: 500;
				letter-spacing: 1px;
				text-transform: uppercase;
				color: $white;
				background: $green;
				border-radius: 3px;
				opacity: .8s;
			}
		}
	}
}
