#trust {
	clear: both;
	padding: rhythm(5) 0 rhythm(3);
	background: $grey-light-background;
	border-bottom: 1px solid $text-1;
	&-title {
		margin-bottom: rhythm(7);
		padding: 0 20px;
		line-height: 20px;
		text-align: center;
		color: $text-light-color;
	}
	&-list {
		text-align: center;
		&.dim-svg > .trust-company {
			@include dimImage;
		}
	}
	&-carousel {
		display: none;
	}
}

#trust.white-background {
	background: $white;
	border-bottom: none;
}

.trust-company {
	display: inline-block;
	width: 180px;
	height: 88px;
	margin: 0 13px 13px;
	> img {
		display: block;
		position: relative;
		width: 100%;
	}
}

#trust-logo {
	&-pr {
		top: -22px;
	}
	&-manpower {
		top: -20px;
	}
}

@include slicer(1) {
	#trust, #trust-partners {
		padding: rhythm(5) 0 rhythm(1);
	}
	.trust-company {
		width: 140px;
	}
}

@include slicer(3) {
	#trust, #trust-partners {
		padding-top: rhythm(4);
		padding-bottom: rhythm(0);
		border: none;
	}
	#trust-title {
		margin-bottom: rhythm(2);
	}
	.trust-company {
		margin-bottom: 0;
	}
	#trust-list {
		display: none;
	}
	.carousel-inner>.item{
		text-align: center
	}
	#trust-carousel {
		display: block;
		width: 100%;
		height: 110px;
		margin: 0 auto;
		> .carousel-controller {
			max-width: 100%;
			> .carousel-control {
				top: 30px;
			}
		}
		img {
			width: 200px;
			height: 88px;
			border-radius: 5px;
			@include dimImage();
		}
	}
}

@include slicer(4) {
	#trust, #trust-partners {
		padding-top: rhythm(3);
		padding-bottom: rhythm(5);
	}
	#trust-title {
		margin-bottom: 0px;
		line-height: 28px;
	}
	#trust-list {
		display: none;
	}
	#trust-carousel {
		display: block;
		width: 100%;
		height: 110px;
		margin: 0 auto;
		> .carousel-controller {
			max-width: 100%;
			> .carousel-control {
				top: 30px;
			}
		}
		img {
			width: 200px;
			height: 88px;
			margin-left: 50px;
			border-radius: 5px;
			@include dimImage();
		}
	}
}
