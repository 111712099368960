#price {
	// nav placeholder background color
	> #nav-outer {
		background: $grey-dark-background;
	}

	// plan section
	&-plan {
		position: relative;
		padding-top: rhythm(15);
		padding-bottom: rhythm(10.5);
		background-image: linear-gradient(0deg, rgba(251,252,252,0.00) 0%, $grey-dark-background 100%);
		overflow: hidden;
		&:after {
			content: "";
			position: absolute;
			top: -5%;
			left: -11%;
			z-index: 1;
			@include SVGsprite('Logo--shape');
		}
		&-title-group {
			position: relative;
			z-index: 10;
			//margin-bottom: rhythm(10);
		}
		&-title {
			margin-bottom: rhythm(2.5);
		}
		&-intro {
			max-width: 810px;
			margin: auto;
			@extend .title-dark-intro;
		}
		// plan tabs
		&-controller {
			position: relative;
			z-index: 10;
			margin: 0 auto rhythm(2);
			max-width: 480px;
			> li {
				width: 50%;
				padding-left: 20px;
				line-height: 44px;
				cursor: pointer;
			}
			.text-highlight {
				display: inline !important;
				padding: 5px !important;
				margin-left: 2px;
				font-size: 14px;
				line-height: 1!important;
				opacity: 0;
				transition: opacity .15s ease-in-out;
			}
			> .active > .text-highlight {
				opacity: 1
			}
		}
		// save 20
		&-save20 {
			margin-bottom: rhythm(8);
			font-size: 14px;
			text-align: center;
			color: $text-light-color;
			opacity: 0;
			transition: opacity .15s ease-in-out;
			> .text-highlight {
				padding: 4px;
			}
		}
		&-cards {
			position: relative;
			z-index: 10;
			margin-bottom: rhythm(12.5);
			background: $white;
			border: 1px solid $grey-dark-border;
			border-radius: 3px;
			@include clearfix;
		}
		&-more {
			margin: 0 auto rhythm(5);
		}
	}
	// plan card
	@at-root {
		.price-card {
			float: left;
			width: 30.75%;
			@at-root {
				#price-plan-single {
					> .price-card-title {
						border-top-left-radius: 3px;
					}
				}
				#price-plan-corporate {
					float: right;
					> .price-card-title {
						border-top-right-radius: 3px;
					}
				}
				.price-card-features-item:last-child {
					border-bottom: none;
					border-bottom-right-radius: 3px;
				}
			}
			&-title {
				padding: rhythm(4) 0 rhythm(2.75);
				text-align: center;
				font-size: 22px;
				line-height: 48px;
				color: $text-7;
				background: $grey-dark-background;
				border-bottom: 1px solid $grey-dark-border;
			}
			&-details {
				padding: 32px 30px 24px;
				text-align: center;
				border-bottom: 1px solid $landing-tab-border;
			}
			&-intro {
				margin-bottom: 25px;
				padding: 0 10px;
				color: $text-gloomy-color;
				line-height: 25px;
				&:after {
					content: "";
					display: block;
					margin: 22px auto 0;
					width: 32px;
					height: 2px;
					background: $landing-tab-border;
				}

			}
			&-price {
				padding-top: 4px;
				margin-left: -12px;
				margin-bottom: 36px;
				font-size: 31px;
				&-symbol {
					font-size: 30px;
					vertical-align: 6px;
					margin-right: -6px;
					display: inline-block;
					font-style: normal;
					color: #ABB3B1 ;
					display: inline-block;
					font-style: normal;
					color: $text-light-color;
				}
				&-number {
					display: inline-block;
					width: 20px;
					font-size: 45px;
					font-weight: 500;
				}
				&-text {
					display: block;
					margin-top: 7px;
					margin-left: 14px;
					font-size: 14px;
					color: $text-light-color;
				}

				&-decimals {
					display: inline-block;
					font-size: 22px;
					font-style: normal;
					vertical-align: 18px;
					color: #3C4241 ;
				}

			}
			&-quote {
				margin-bottom: 28px;
				padding: 0 10px;
				font-size: 18px;
				line-height: 32px;
			}
			&-info {
				color: $text-light-color;
				font-size: 14px;
				> .text-highlight {
					padding: 5px 3px;
				}
			}
			&-btn {
				display: block;
				height: 56px;
				padding: 15px;
				margin-bottom: 14px;
				font-size: 18px;
				line-height: 26px;
				text-align: center;
			}
			// team plan
			&.recommend {
				position: absolute;
				top: -41px;
				bottom: -40px;
				left: 30.75%;
				z-index: 10;
				width: 38.5%;
				background: $white;
				border: 1px solid $green;
				border-radius: 3px;
				> .price-card-title {
					padding-top: 10px;
					padding-bottom: 24px;
					font-size: 31px;
					color: $white;
					background: $green;
					border-color: $green;
					> span, > strong {
						content: "";
						display: block;
						margin-bottom: rhythm(1.5);
						font-weight: 500;
						font-size: 16px;
						letter-spacing: 2px;
						text-transform: uppercase;
						color: $green-1;
					}
				}
				> .price-card-details {
					padding-left: 75px;
					padding-right: 75px;
					> .price-card-intro:after {
						background: $green;
					}
				}
				> .price-card-features > .price-card-features-item {
					padding-left: 75px;
				}
			}
		}
		// plan entries
		.price-card-features {
			&-item {
				// 48px height
				padding: 11px 30px 9px;
				line-height: 27px;
				color: $text-gloomy-color;
				border-bottom: 1px solid $grey-light-border;
				&:before {
					content: "";
					float: left;
					width: 6px;
					height: 6px;
					margin-top: 9px;
					margin-right: 14px;
					background: $landing-tab-active-border;
					border-radius: 3px;
				}
				&.emphases {
					color: $text-black-color;
				}
				&.plus {
					background: $grey-light-background;
					&:before {
						margin-top: 8px;
						margin-right: 13px;
						@include SVGsprite('Icon--plus');
					}
				}
				&.has-tooltip {
					position: relative;
					width: 100%;
					padding-right: 70px;
					&:after {
						content: "";
						position: absolute;
						top: 14px;
						right: 30px;
						@include SVGsprite('Icon--info');
					}
				}
			}
		}
	}

	// price table section
	&-table-wrap {
		display: none;
		position: relative;
		padding-bottom: rhythm(12);
	}
	&-table-container {
		width: 100%;
		padding-bottom: rhythm(3);
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		user-select: none;
	}
	&-table-header-wrap {
		position: relative;
		height: 113px;
	}
	&-table-header {
		width: 100%;
		min-width: 960px;
		border-collapse: separate;
		border-spacing: 0;
		background: $white;
		tr:first-of-type {
			> td:last-of-type {
				border-top-right-radius: 3px;
			}
			> td:nth-child(2) {
				border-top-left-radius: 3px;
				border-left: solid 1px $grey;
			}
		}
	}
	&-table {
		width: 100%;
		min-width: 960px;
		border-collapse: separate;
		border-spacing: 0;
		tr:last-of-type {
			> td {
				border-bottom: 1px solid $grey;
			}
			> td:first-of-type {
				border-bottom-left-radius: 3px
			}
			> td:last-of-type {
				border-bottom-right-radius: 3px;
			}

		}
		tr:first-of-type {
			> td {
				border-top: solid 1px $red;
				&:first-child{
					border-top: 1px solid $grey;
					border-top-left-radius: 3px;
				}
				&:last-child{
					border-top: solid 1px $green;
				}
			}
		}
	}

	&-table-header-affix > .container-9--gutter {
		position: relative;
	}
	&-table-close {
		position: absolute;
		top: 10px;
		right: -26px;
		@include SVGsprite('Icon--close-grey');
		opacity: .7;
		transition: opacity .15s ease-in-out;
		&:hover {
			opacity: 1;
		}
	}

	@at-root {
		.table-column-width {
			width: 270px;
		}
		.text-center {
			text-align: center;
			> strong {
				font-weight: normal;
			}
		}
		td {
			padding: 10px 30px 9px;
			font-size: 14px;
			line-height: 20px;
			color: $text-dark-color;
			border-top: 1px solid $grey;
			border-right: 1px solid $grey;
			&:first-child {
				border-left: 1px solid $grey;
			}
			> span {
				color: #B5BBBA;
			}
			&.has-tooltip {
				position: relative;
				padding-right: 70px;
				&:after {
					content: "";
					position: absolute;
					top: 10px;
					right: 30px;
					@include SVGsprite('Icon--info');
				}
			}
		}
		.price-table {
			&-title-empty {
				border-top: none;
				border-left: none !important;
				border-right: solid 1px $white;
			}
			&-title {
				padding: rhythm(2) 0;
				text-align: center;
				font-size: 22px;
				line-height: 32px;
				border-top: 1px solid $grey;
			}
			&-row-title {
				position: relative;
				height: 55px;
				border: none;
			}
			&-header {
				display: block;
				position: absolute;
				top: 0;
				right: 0;
				left: -1px;
				bottom: -1px;
				z-index: 10;
				padding: 10px 30px 9px;
				font-size: 18px;
				line-height: 36px;
				color: $white;
				background: $grey-dark-border;
			}
			&-row-subtitle {
				background: $grey-dark-background;
				> td {
					padding-top: 15px;
					padding-bottom: 12px;
					font-size: 16px;
					color: $text-black-color;
				}
			}
			&-btn {
				padding: 0;
				border-top: none !important;
				border-right: solid 1px darken($red,10);
				&:last-child{
					border-right: solid 1px $green;
				}
				> a {
					display: block;
					font-size: 16px;
					line-height: 48px;
					border-radius: 0;
					cursor: pointer;
				}
			}
			&-support {
				padding: 0;
				&:before {
					content: "";
					display: block;
					margin: auto;
					@include SVGsprite('Icon--tick');
				}
			}
			&-unsupport {
				padding: 0;
				background: $grey-light-background;
				&:before {
					content: "";
					display: block;
					margin: auto;
					@include SVGsprite('Icon--cross');
				}
			}
		}
	}

	// features section
	&-features {
		padding-top: rhythm(14.75);
		background: $grey-dark-background;
		&-items {
			margin-bottom: rhythm(5);
		}
	}
	@at-root {
		.price-features-row {
			@include clearfix;
		}
		.price-features-item--left,
		.price-features-item--right {
			position: relative;
			padding-left: 160px;
			margin-bottom: rhythm(5);
			color: $text-grey-color;
			&:before {
				content: "";
				position: absolute;
				top: -10px;
				left: 25px;
			}
			> h3 {
				font-weight: 400;
				margin-bottom: rhythm(1.5);
			}
		}
		.price-features-item--right {
			padding-left: 160px + 21;
			&:before {
				left: 46px
			}
		}
	}

	// testimonial section
	&-testimonial {
		padding: rhythm(10) 10px rhythm(14.5);
		margin: auto;
		max-width: 1000px;
	}

	// faq seciton
	&-faq {
		padding-top: rhythm(15);
		border-top: 1px solid $grey-light-border;
		&-entries {
			margin-bottom: rhythm(14.5);
		}
	}
	@at-root {
		.price-faq-row {
			@include clearfix;
		}
		.price-faq-entry--left,
		.price-faq-entry--right {
			margin-bottom: rhythm(5.75);
			> h4 {
				margin-bottom: rhythm(.5);
				font-weight: normal;
				color: $text-black-color;
			}
			> p {
				color: $text-grey-color;
				&:last-child {
					margin-bottom: 0;
				}
				> strong {
					font-weight: normal;
					color: $text-black-color;
				}
				> em {
					@extend .text-highlight;
					font-style: normal;
					padding: 3px;
				}
				> a {
					@extend .link-green;
				}
			}
		}
	}
}
