// title group
.container-title {
	max-width: 940px;
	padding-left: 20px;
	padding-right: 20px;
	margin: auto;
	margin-bottom: rhythm(8);
	text-align: center;
}

// title font
h1, h2, h3, h4, h5 {
	margin: 0;
	font-weight: 500;
	color: $title-color;
}

h1, .primary-title {
	font-size: 45px;
	line-height: 56px;
}

h2 {
	font-size: 32px;
	line-height: 48px;
}

h3 {
	font-size: 22px;
	line-height: 32px;
}

h4, .large-text {
	font-size: 18px;
	line-height: 32px;
}

h5 {
	font-size: 16px;
	line-height: 24px;
}

// regular content page
.content-page {
	padding: rhythm(12.5) 0;

	h2 {
		margin-bottom: rhythm(3);
	}
	h3 {
		margin: rhythm(4) 0 rhythm(1);
	}
	p > a {
		@extend %transform-links;
	}
	p + p {
		margin-top: rhythm(3);
	}
}

.title-intro {
	font-size: 18px;
	line-height: 32px;
	color: $text-gloomy-color;
}
.title-dark-intro {
	font-size: 22px;
	line-height: 32px;
	color: $text-dark-color;
}


// text font
.text-black {
	color: $text-black-color;
}
.text-dark {
	color: $text-dark-color;
}
.text-gloomy {
	color: $text-gloomy-color;
}
.text-grey {
	color: $text-grey-color;
}
.text-light {
	color: $text-light-color;
}
.text-highlight {
	color: $text-hightlight-color;
	background: $text-hightlight-background;
	border-radius: 2px;
}

.link-green {
	color: $link-green-color;
	text-decoration: none;
	border-bottom: .08em solid $grey-light-border;
	padding-bottom: .08em;
	box-sizing:border-box;
	transition: border-color .1s linear;

	&:hover {
		border-color: $link-green-color;
	}
}

%transform-links
 {
	color: $link-green-color;
	text-decoration: none;
	border-bottom: .08em solid $grey-light-border;
	padding-bottom: .08em;
	box-sizing:border-box;
	transition: border-color .1s linear;

	&:hover {
		border-color: $link-green-color;
	}
}

p > a {
	@extend %transform-links;
}

p strong, li strong {
	font-weight: normal;
	color: $text-black-color;
}

@include slicer(2) {
	.container-title {
		max-width: 660px;
	}
	h1, .primary-title {
		font-size: 32px;
		line-height: 48px;
	}
}
@include slicer(3) {
	.container-title {
		max-width: 590px;
		margin-bottom: rhythm(5);
	}
	.primary-title {
		font-size: 28px;
		line-height: 42px;
	}
	h2 {
		font-size: 28px;
	}
}
@include slicer(4) {
	.container-title {
		margin-bottom: rhythm(3);
	}
	.primary-title, h1, h2 {
		font-size: 24px;
		line-height: 32px;
		margin-bottom: rhythm(3);
	}
	.large-text {
		font-size: 16px;
	}
	.title-intro, .title-dark-intro {
		font-size: 16px;
		line-height: 28px;
		margin-bottom: rhythm(2);
	}
}
